import logo from './logo.svg';
import './App.css';
import './main.css';
import './custom.css';
import { connect } from 'react-redux';
import Papa from 'papaparse';
import React, { Component } from "react";
import * as XLSX from 'xlsx';
import classification from './misconception_classification.csv';
import misconception from './misconception.csv';
import { grinTongueSquint } from 'fontawesome';
import { faBriefcaseClock } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';
import { Tooltip } from 'react-tooltip';
import bg from './assets/images/bg.jpg';
import login from './login.csv';
import { FlippingCard, FlippingCardBack, FlippingCardFront, UserCard } from 'react-ui-cards';
import axios from 'axios';
import exploringProbabilityStudentReasoning from './exploringProbability.json';
import Q1 from './assets/images/Questions/Question 1.jpg';
import Q2 from './assets/images/Questions/Question 2.jpg';
import Q3 from './assets/images/Questions/Question 3.jpg';
import Q4 from './assets/images/Questions/Question 4.jpg';
import Q5 from './assets/images/Questions/Question 5.jpg';
import Q6 from './assets/images/Questions/Question 6.jpg';
import Q7 from './assets/images/Questions/Question 7.jpg';
import Q8 from './assets/images/Questions/Question 8.jpg';
import Q9 from './assets/images/Questions/Question 9.jpg';
import Q10 from './assets/images/Questions/Question 10.jpg';
import Q11 from './assets/images/Questions/Question 11.jpg';
import Q12 from './assets/images/Questions/Question 12.jpg';
import Q13 from './assets/images/Questions/Question 13.jpg';
import Q14 from './assets/images/Questions/Question 14.jpg';
import Q15 from './assets/images/Questions/Question 15.jpg';
import { CircularProgressbar, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import CQ1 from './assets/images/ChanceAndDataQuestions/Question 1.jpg';
import CQ2 from './assets/images/ChanceAndDataQuestions/Question 2.jpg';
import CQ3 from './assets/images/ChanceAndDataQuestions/Question 3.jpg';
import CQ4 from './assets/images/ChanceAndDataQuestions/Question 4.jpg';
import CQ5 from './assets/images/ChanceAndDataQuestions/Question 5.jpg';
import CQ6 from './assets/images/ChanceAndDataQuestions/Question 6.jpg';
import CQ7 from './assets/images/ChanceAndDataQuestions/Question 7.jpg';
import CQ8 from './assets/images/ChanceAndDataQuestions/Question 8.jpg';
import CQ9 from './assets/images/ChanceAndDataQuestions/Question 9.jpg';
import CQ10 from './assets/images/ChanceAndDataQuestions/Question 10.jpg';
import CQ11 from './assets/images/ChanceAndDataQuestions/Question 11.jpg';
import CQ12 from './assets/images/ChanceAndDataQuestions/Question 12.jpg';
import CQ13 from './assets/images/ChanceAndDataQuestions/Question 13.jpg';
import CQ14 from './assets/images/ChanceAndDataQuestions/Question 14.jpg';
import ChanceAndData from './App';

class AppCopy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parsedData: [],
      tableRows: [],
      values: [],
      classification: [],
      need_support_equiprobability_solid_blue: [],
      need_support_equiprobability_open_blue: [],
      does_not_need_support_equiprobability_solid_green: [],
      does_not_need_support_equiprobability_open_green: [],
      need_support_ignore_solid_blue: [],
      need_support_ignore_open_blue: [],
      does_not_need_support_ignore_open_green: [],
      does_not_need_support_ignore_solid_green: [],
      incomplete_equiprobability: [],
      imcomplete_ignore: [],
      misconception: [],
      misconceptionMatrix: [],
      scoreMatrix: [],
      columnDetailedDataTab: [],
      email: "",
      password: "",
      cpassword: "",
      firstName: "",
      lastName: "",
      loggedIn: false,
      loginData: [],
      loginErrorDisplay: false,
      exploringProbability: false,
      chanceAndData: false,
      showDashboard: true,
      createAccountShow: false,
      equiprobabilityPercent: [],
      ignoreprobabilityPercent: [],
      APIresponseMisconceptionData: [],

    };
    this.importCSV = this.importCSV.bind(this);
    this.updateData = this.updateData.bind(this);

  }

  importCSV = (event) => {
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: this.updateData
    });

    if (this.state.exploringProbability == true) {
      if (event.target.files[0]) {
        const formData = new FormData();
        formData.append('file', event.target.files[0]);
        axios
          //.post('http://localhost:8080/uploadEquiprobability', formData)
          .post('http://dice.coe.uga.edu/uploadEquiprobability', formData)
          .then((response) => {
            console.log(response.data);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
    if (this.state.chanceAndData == true) {
      if (event.target.files[0]) {
        const formData = new FormData();
        formData.append('file', event.target.files[0]);
        axios
          //.post('http://localhost:8080/uploadChanceAndData', formData)
          .post('http://dice.coe.uga.edu/uploadChanceAndData', formData)
          .then((response) => {
            console.log(response.data);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }

  }

  updateData = (results) => {
    let rowsArray = [];
    let valuesArray = [];

    // Iterating data to get column name and their values
    results.data.map((d) => {
      rowsArray.push(Object.keys(d));
      valuesArray.push(Object.values(d));
    });
    // console.log(results.data);
    this.setState({
      parsedData: results.data,
      tableRows: rowsArray[0],
      values: valuesArray
    });

    if (this.state.exploringProbability == true) {
      //let url = 'http://127.0.0.1:8000/predict_exploring_probability';
      let url = 'http://dice.coe.uga.edu/predict_exploring_probability';
      let data = results.data;

      fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          // Add any additional headers if required
        },
        body: JSON.stringify(data)
      })
        .then(response => response.json())
        .then(result => {
          // Handle the response result
          console.log(result);
          this.setState({
            equiprobabilityPercent: result.ePercents,
            ignoreprobabilityPercent: result.iPercents,
            APIresponseMisconceptionData: result.studentResponses
          })
          this.getClassificationEquiprobability(result.studentResponses);
          this.getClassificationIgnore(result.studentResponses);
        })
        .catch(error => {
          // Handle any errors that occurred during the request
          console.error('Error:', error);
        });
    }

    this.getMisconceptionMatrix(results.data);

  }


  getClassificationData = async () => {
    let response = await fetch(classification)
    let reader = response.body.getReader()
    let result = await reader.read() // raw array
    let decoder = new TextDecoder('utf-8')
    let csv = decoder.decode(result.value) // the csv text
    let results = Papa.parse(csv, { header: true }) // object with { data, errors, meta }
    let rows = results.data // array of objects
    this.setState({ classification: rows });
    // this.getClassificationEquiprobability();
    // this.getClassificationIgnore();
  }

  getMisconceptionData = async () => {
    let response = await fetch(misconception)
    let reader = response.body.getReader()
    let result = await reader.read() // raw array
    let decoder = new TextDecoder('utf-8')
    let csv = decoder.decode(result.value) // the csv text
    let results = Papa.parse(csv, { header: true }) // object with { data, errors, meta }
    let rows = results.data // array of objects
    this.setState({ misconception: rows });
  }

  getMisconceptionMatrix = (parsedData) => {
    let misconceptionMatrix = [];
    let scoreMatrix = [];
    parsedData.forEach((item1, index1) => {
      let rowMatrix = [];
      let score = [];
      let countC = 0;
      let countI = 0;
      let countE = 0;
      this.state.misconception.forEach((item2, index2) => {
        if (item2.Form == 1 && item2.Item == 'Q1' && item2.Response == item1.Q1) {
          rowMatrix.push({ 'Q1': item2.Misconception, 'Username': item1.Username, 'Firstname': item1.Firstname, 'Lastname': item1.Lastname })
          score.push({ 'Q1': item2.Score })
          if (item2.Misconception == 'C') {
            countC++;
          }
          else if (item2.Misconception == 'I') {
            countI++;
          }
          else if (item2.Misconception == 'E') {
            countE++;
          }
        }
        else if (item2.Form == 1 && item2.Item == 'Q2' && item2.Response == item1.Q2) {
          rowMatrix.push({ 'Q2': item2.Misconception, 'Username': item1.Username, 'Firstname': item1.Firstname, 'Lastname': item1.Lastname })
          score.push({ 'Q2': item2.Score })
          if (item2.Misconception == 'C') {
            countC++;
          }
          else if (item2.Misconception == 'I') {
            countI++;
          }
          else if (item2.Misconception == 'E') {
            countE++;
          }
        }
        else if (item2.Form == 1 && item2.Item == 'Q3' && item2.Response == item1.Q3) {
          rowMatrix.push({ 'Q3': item2.Misconception, 'Username': item1.Username, 'Firstname': item1.Firstname, 'Lastname': item1.Lastname })
          score.push({ 'Q3': item2.Score })
          if (item2.Misconception == 'C') {
            countC++;
          }
          else if (item2.Misconception == 'I') {
            countI++;
          }
          else if (item2.Misconception == 'E') {
            countE++;
          }
        }
        else if (item2.Form == 1 && item2.Item == 'Q4' && item2.Response == item1.Q4) {
          rowMatrix.push({ 'Q4': item2.Misconception, 'Username': item1.Username, 'Firstname': item1.Firstname, 'Lastname': item1.Lastname })
          score.push({ 'Q4': item2.Score })
          if (item2.Misconception == 'C') {
            countC++;
          }
          else if (item2.Misconception == 'I') {
            countI++;
          }
          else if (item2.Misconception == 'E') {
            countE++;
          }
        }
        else if (item2.Form == 1 && item2.Item == 'Q5' && item2.Response == item1.Q5) {
          rowMatrix.push({ 'Q5': item2.Misconception, 'Username': item1.Username, 'Firstname': item1.Firstname, 'Lastname': item1.Lastname })
          score.push({ 'Q5': item2.Score })
          if (item2.Misconception == 'C') {
            countC++;
          }
          else if (item2.Misconception == 'I') {
            countI++;
          }
          else if (item2.Misconception == 'E') {
            countE++;
          }
        }
        else if (item2.Form == 1 && item2.Item == 'Q6' && item2.Response == item1.Q6) {
          rowMatrix.push({ 'Q6': item2.Misconception, 'Username': item1.Username, 'Firstname': item1.Firstname, 'Lastname': item1.Lastname })
          score.push({ 'Q6': item2.Score })
          if (item2.Misconception == 'C') {
            countC++;
          }
          else if (item2.Misconception == 'I') {
            countI++;
          }
          else if (item2.Misconception == 'E') {
            countE++;
          }
        }
        else if (item2.Form == 1 && item2.Item == 'Q7' && item2.Response == item1.Q7) {
          rowMatrix.push({ 'Q7': item2.Misconception, 'Username': item1.Username, 'Firstname': item1.Firstname, 'Lastname': item1.Lastname })
          score.push({ 'Q7': item2.Score })
          if (item2.Misconception == 'C') {
            countC++;
          }
          else if (item2.Misconception == 'I') {
            countI++;
          }
          else if (item2.Misconception == 'E') {
            countE++;
          }
        }
        else if (item2.Form == 1 && item2.Item == 'Q8' && item2.Response == item1.Q8) {
          rowMatrix.push({ 'Q8': item2.Misconception, 'Username': item1.Username, 'Firstname': item1.Firstname, 'Lastname': item1.Lastname })
          score.push({ 'Q8': item2.Score })
          if (item2.Misconception == 'C') {
            countC++;
          }
          else if (item2.Misconception == 'I') {
            countI++;
          }
          else if (item2.Misconception == 'E') {
            countE++;
          }
        }
        else if (item2.Form == 1 && item2.Item == 'Q9' && item2.Response == item1.Q9) {
          rowMatrix.push({ 'Q9': item2.Misconception, 'Username': item1.Username, 'Firstname': item1.Firstname, 'Lastname': item1.Lastname })
          score.push({ 'Q9': item2.Score })
          if (item2.Misconception == 'C') {
            countC++;
          }
          else if (item2.Misconception == 'I') {
            countI++;
          }
          else if (item2.Misconception == 'E') {
            countE++;
          }
        }
        else if (item2.Form == 1 && item2.Item == 'Q10' && item2.Response == item1.Q10) {
          rowMatrix.push({ 'Q10': item2.Misconception, 'Username': item1.Username, 'Firstname': item1.Firstname, 'Lastname': item1.Lastname })
          score.push({ 'Q10': item2.Score })
          if (item2.Misconception == 'C') {
            countC++;
          }
          else if (item2.Misconception == 'I') {
            countI++;
          }
          else if (item2.Misconception == 'E') {
            countE++;
          }
        }
        else if (item2.Form == 1 && item2.Item == 'Q11' && item2.Response == item1.Q11) {
          rowMatrix.push({ 'Q11': item2.Misconception, 'Username': item1.Username, 'Firstname': item1.Firstname, 'Lastname': item1.Lastname })
          score.push({ 'Q11': item2.Score })
          if (item2.Misconception == 'C') {
            countC++;
          }
          else if (item2.Misconception == 'I') {
            countI++;
          }
          else if (item2.Misconception == 'E') {
            countE++;
          }
        }
        else if (item2.Form == 1 && item2.Item == 'Q12' && item2.Response == item1.Q12) {
          rowMatrix.push({ 'Q12': item2.Misconception, 'Username': item1.Username, 'Firstname': item1.Firstname, 'Lastname': item1.Lastname })
          score.push({ 'Q12': item2.Score })
          if (item2.Misconception == 'C') {
            countC++;
          }
          else if (item2.Misconception == 'I') {
            countI++;
          }
          else if (item2.Misconception == 'E') {
            countE++;
          }
        }
        else if (item2.Form == 1 && item2.Item == 'Q13' && item2.Response == item1.Q13) {
          rowMatrix.push({ 'Q13': item2.Misconception, 'Username': item1.Username, 'Firstname': item1.Firstname, 'Lastname': item1.Lastname })
          score.push({ 'Q13': item2.Score })
          if (item2.Misconception == 'C') {
            countC++;
          }
          else if (item2.Misconception == 'I') {
            countI++;
          }
          else if (item2.Misconception == 'E') {
            countE++;
          }
        }
        else if (item2.Form == 1 && item2.Item == 'Q14' && item2.Response == item1.Q14) {
          rowMatrix.push({ 'Q14': item2.Misconception, 'Username': item1.Username, 'Firstname': item1.Firstname, 'Lastname': item1.Lastname })
          score.push({ 'Q14': item2.Score })
          if (item2.Misconception == 'C') {
            countC++;
          }
          else if (item2.Misconception == 'I') {
            countI++;
          }
          else if (item2.Misconception == 'E') {
            countE++;
          }
        }
        else if (item2.Form == 1 && item2.Item == 'Q15' && item2.Response == item1.Q15) {
          rowMatrix.push({ 'Q15': item2.Misconception, 'Username': item1.Username, 'Firstname': item1.Firstname, 'Lastname': item1.Lastname })
          score.push({ 'Q15': item2.Score })
          if (item2.Misconception == 'C') {
            countC++;
          }
          else if (item2.Misconception == 'I') {
            countI++;
          }
          else if (item2.Misconception == 'E') {
            countE++;
          }
        }
      })

      rowMatrix.push({ 'C': countC, 'I': countI, 'E': countE })
      misconceptionMatrix.push(rowMatrix);
      scoreMatrix.push(score);
    })
    this.setState({
      misconceptionMatrix: misconceptionMatrix,
      scoreMatrix: scoreMatrix
    })
    this.getDetailedDataPercentages(misconceptionMatrix);
  }

  getClassificationIgnore = (studentResponses) => {
    let solid_green = [];
    let open_green = [];
    let solid_blue = [];
    let open_blue = [];

    studentResponses.forEach((item, index) => {
      switch (item.IgnoringIndependence_FINAL) {
        case "Solid green": solid_green.push(item.Username); break;
        case "Solid blue": solid_blue.push(item.Username); break;
        case "Open green": open_green.push(item.Username); break;
        case "Open blue": open_blue.push(item.Username); break;
      }
    })
    this.setState({
      does_not_need_support_ignore_solid_green: solid_green,
      need_support_ignore_solid_blue: solid_blue,
      need_support_ignore_open_blue: open_blue,
      does_not_need_support_ignore_open_green: open_green,
    })
  }

  getClassificationEquiprobability = (studentResponses) => {
    let solid_green = [];
    let open_green = [];
    let solid_blue = [];
    let open_blue = [];

    studentResponses.forEach((item, index) => {
      switch (item.EverythingEquallyLikely_FINAL) {
        case "Solid green": solid_green.push(item.Username); break;
        case "Solid blue": solid_blue.push(item.Username); break;
        case "Open green": open_green.push(item.Username); break;
        case "Open blue": open_blue.push(item.Username); break;
      }
    })
    this.setState({
      does_not_need_support_equiprobability_solid_green: solid_green,
      need_support_equiprobability_solid_blue: solid_blue,
      need_support_equiprobability_open_blue: open_blue,
      does_not_need_support_equiprobability_open_green: open_green,
    })
  }

  getDetailedDataPercentages = (matrix) => {
    let totalCountC = [{
      Q1: 0,
      Q2: 0,
      Q3: 0,
      Q4: 0,
      Q5: 0,
      Q6: 0,
      Q7: 0,
      Q8: 0,
      Q9: 0,
      Q10: 0,
      Q11: 0,
      Q12: 0,
      Q13: 0,
      Q14: 0,
      Q15: 0
    }];
    let totalCountE = [{
      Q1: 0,
      Q2: 0,
      Q3: 0,
      Q4: 0,
      Q5: 0,
      Q6: 0,
      Q7: 0,
      Q8: 0,
      Q9: 0,
      Q10: 0,
      Q11: 0,
      Q12: 0,
      Q13: 0,
      Q14: 0,
      Q15: 0
    }];
    let totalCountI = [{
      Q1: 0,
      Q2: 0,
      Q3: 0,
      Q4: 0,
      Q5: 0,
      Q6: 0,
      Q7: 0,
      Q8: 0,
      Q9: 0,
      Q10: 0,
      Q11: 0,
      Q12: 0,
      Q13: 0,
      Q14: 0,
      Q15: 0
    }];
    matrix.forEach((item, index1) => {
      switch (item[0].Q1) {
        case 'I': totalCountI[0].Q1 = totalCountI[0].Q1 + 1; break;
        case 'E': totalCountE[0].Q1 = totalCountE[0].Q1 + 1; break;
        case 'C': totalCountC[0].Q1 = totalCountC[0].Q1 + 1; break;
      }
      switch (item[1].Q2) {
        case 'I': totalCountI[0].Q2 = totalCountI[0].Q2 + 1; break;
        case 'E': totalCountE[0].Q2 = totalCountE[0].Q2 + 1; break;
        case 'C': totalCountC[0].Q2 = totalCountC[0].Q2 + 1; break;
      }switch (item[2].Q3) {
        case 'I': totalCountI[0].Q3 = totalCountI[0].Q3 + 1; break;
        case 'E': totalCountE[0].Q3 = totalCountE[0].Q3 + 1; break;
        case 'C': totalCountC[0].Q3 = totalCountC[0].Q3 + 1; break;
      }switch (item[3].Q4) {
        case 'I': totalCountI[0].Q4 = totalCountI[0].Q4 + 1; break;
        case 'E': totalCountE[0].Q4 = totalCountE[0].Q4 + 1; break;
        case 'C': totalCountC[0].Q4 = totalCountC[0].Q4 + 1; break;
      }switch (item[4].Q5) {
        case 'I': totalCountI[0].Q5 = totalCountI[0].Q5 + 1; break;
        case 'E': totalCountE[0].Q5 = totalCountE[0].Q5 + 1; break;
        case 'C': totalCountC[0].Q5 = totalCountC[0].Q5 + 1; break;
      }switch (item[5].Q6) {
        case 'I': totalCountI[0].Q6 = totalCountI[0].Q6 + 1; break;
        case 'E': totalCountE[0].Q6 = totalCountE[0].Q6 + 1; break;
        case 'C': totalCountC[0].Q6 = totalCountC[0].Q6 + 1; break;
      }switch (item[6].Q7) {
        case 'I': totalCountI[0].Q7 = totalCountI[0].Q7 + 1; break;
        case 'E': totalCountE[0].Q7 = totalCountE[0].Q7 + 1; break;
        case 'C': totalCountC[0].Q7 = totalCountC[0].Q7 + 1; break;
      }switch (item[7].Q8) {
        case 'I': totalCountI[0].Q8 = totalCountI[0].Q8 + 1; break;
        case 'E': totalCountE[0].Q8 = totalCountE[0].Q8 + 1; break;
        case 'C': totalCountC[0].Q8 = totalCountC[0].Q8 + 1; break;
      }switch (item[8].Q9) {
        case 'I': totalCountI[0].Q9 = totalCountI[0].Q9 + 1; break;
        case 'E': totalCountE[0].Q9 = totalCountE[0].Q9 + 1; break;
        case 'C': totalCountC[0].Q9 = totalCountC[0].Q9 + 1; break;
      }switch (item[9].Q10) {
        case 'I': totalCountI[0].Q10 = totalCountI[0].Q10 + 1; break;
        case 'E': totalCountE[0].Q10 = totalCountE[0].Q10 + 1; break;
        case 'C': totalCountC[0].Q10 = totalCountC[0].Q10 + 1; break;
      }switch (item[10].Q11) {
        case 'I': totalCountI[0].Q11 = totalCountI[0].Q11 + 1; break;
        case 'E': totalCountE[0].Q11 = totalCountE[0].Q11 + 1; break;
        case 'C': totalCountC[0].Q11 = totalCountC[0].Q11 + 1; break;
      }switch (item[11].Q12) {
        case 'I': totalCountI[0].Q12 = totalCountI[0].Q12 + 1; break;
        case 'E': totalCountE[0].Q12 = totalCountE[0].Q12 + 1; break;
        case 'C': totalCountC[0].Q12 = totalCountC[0].Q12 + 1; break;
      }switch (item[12].Q13) {
        case 'I': totalCountI[0].Q13 = totalCountI[0].Q13 + 1; break;
        case 'E': totalCountE[0].Q13 = totalCountE[0].Q13 + 1; break;
        case 'C': totalCountC[0].Q13 = totalCountC[0].Q13 + 1; break;
      }switch (item[13].Q14) {
        case 'I': totalCountI[0].Q14 = totalCountI[0].Q14 + 1; break;
        case 'E': totalCountE[0].Q14 = totalCountE[0].Q14 + 1; break;
        case 'C': totalCountC[0].Q14 = totalCountC[0].Q14 + 1; break;
      }switch (item[14].Q15) {
        case 'I': totalCountI[0].Q15 = totalCountI[0].Q15 + 1; break;
        case 'E': totalCountE[0].Q15 = totalCountE[0].Q15 + 1; break;
        case 'C': totalCountC[0].Q15 = totalCountC[0].Q15 + 1; break;
      }
    })
    let detailedDataColumn = []
    detailedDataColumn.push({ 'colCountC': totalCountC, 'colCountE': totalCountE, 'colCountI': totalCountI });
    this.setState({ columnDetailedDataTab: detailedDataColumn });
  }


  percentColC = () => {
    if (this.state.values > 0) {
      this.state.columnDetailedDataTab[0].colCountC[0].map((item, index) => {
        return (
          <>
            <td className="bb3p"><b>{Math.ceil(((item.Q1) * 100) / this.state.values.length)}</b></td>
            <td className="bb3p"><b>{Math.ceil(((item.Q2) * 100) / this.state.values.length)}</b></td>
            <td className="bb3p"><b>{Math.ceil(((item.Q3) * 100) / this.state.values.length)}</b></td>
            <td className="bb3p"><b>{Math.ceil(((item.Q4) * 100) / this.state.values.length)}</b></td>
            <td className="bb3p"><b>{Math.ceil(((item.Q5) * 100) / this.state.values.length)}</b></td>
            <td className="bb3p"><b>{Math.ceil(((item.Q6) * 100) / this.state.values.length)}</b></td>
            <td className="bb3p"><b>{Math.ceil(((item.Q7) * 100) / this.state.values.length)}</b></td>
            <td className="bb3p"><b>{Math.ceil(((item.Q8) * 100) / this.state.values.length)}</b></td>
            <td className="bb3p"><b>{Math.ceil(((item.Q9) * 100) / this.state.values.length)}</b></td>
            <td className="bb3p"><b>{Math.ceil(((item.Q10) * 100) / this.state.values.length)}</b></td>
            <td className="bb3p"><b>{Math.ceil(((item.Q11) * 100) / this.state.values.length)}</b></td>
            <td className="bb3p"><b>{Math.ceil(((item.Q12) * 100) / this.state.values.length)}</b></td>
            <td className="bb3p"><b>{Math.ceil(((item.Q13) * 100) / this.state.values.length)}</b></td>
            <td className="bb3p"><b>{Math.ceil(((item.Q14) * 100) / this.state.values.length)}</b></td>
            <td className="bb3p"><b>{Math.ceil(((item.Q15) * 100) / this.state.values.length)}</b></td>
          </>
        )
      })
    }
    else {
      return (
        <td></td>
      )
    }
  }

  exploringProbabilityShow = () => {
    this.setState({ exploringProbability: true, showDashboard: false })
  }

  chanceAndDataShow = () => {
    this.setState({ chanceAndData: true, showDashboard: false })
  }

  componentDidMount() {
    this.getClassificationData();
    this.getMisconceptionData();

    //fetch('http://127.0.0.1:8000/health-check', {
    fetch('http://dice.coe.uga.edu/health-check', {
      method: 'GET',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json',
        // Add any additional headers if required
      },
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  showQuestionEquiprobability = (ItemID) => {
    switch (ItemID) {
      case "Q1": return Q1; break;
      case "Q2": return Q2; break;
      case "Q3": return Q3; break;
      case "Q4": return Q4; break;
      case "Q5": return Q5; break;
      case "Q6": return Q6; break;
      case "Q7": return Q7; break;
      case "Q8": return Q8; break;
      case "Q9": return Q9; break;
      case "Q10": return Q10; break;
      case "Q11": return Q11; break;
      case "Q12": return Q12; break;
      case "Q13": return Q13; break;
      case "Q14": return Q14; break;
      case "Q15": return Q15; break;
    }
  }

  findItemById = (arr, id) => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].id == id) {
        return arr[i];
      }
    }
  }

  showExampleStudentReasoningEquiprobability = (item, misconception) => {
    let foundJsonItem;

    if (misconception === "Equiprobability") {
      if (item.ItemID === "Q4") {
        foundJsonItem = this.findItemById(exploringProbabilityStudentReasoning, "EQ4");
      } else if (item.ItemID === "Q13") {
        foundJsonItem = this.findItemById(exploringProbabilityStudentReasoning, "EQ13");
      } else {
        foundJsonItem = this.findItemById(exploringProbabilityStudentReasoning, item.ItemID);
      }
    } else if (misconception === "Ignore") {
      if (item.ItemID === "Q4") {
        foundJsonItem = this.findItemById(exploringProbabilityStudentReasoning, "IQ4");
      } else if (item.ItemID === "Q13") {
        foundJsonItem = this.findItemById(exploringProbabilityStudentReasoning, "IQ13");
      } else {
        foundJsonItem = this.findItemById(exploringProbabilityStudentReasoning, item.ItemID);
      }
    }
    

    return (
      <>
        <div style={{ maxWidth: "70%", maxHeight: "70%", alignContent: "center", whiteSpace: "pre-wrap" }} 
        title={this.showTooltipForProgressBar(item,misconception)}
        >
          <CircularProgressbarWithChildren value={Math.ceil((misconception === "Equiprobability" ? item.EResponseProp : item.IResponseProp) * 100)}>
            <div style={{ fontSize: "30px", color: "#337ab7" }}>
              {Math.ceil((misconception === "Equiprobability" ? item.EResponseProp : item.IResponseProp) * 100)}% <br /> 
              {foundJsonItem.text}
            </div>
          </CircularProgressbarWithChildren>
        </div>
        <br /><br />
        <h3><strong>Example Student Reasoning</strong></h3>
        {foundJsonItem.misconception}
      </>
    );
  }

  showTooltipForProgressBar = (item,misconception) => {
    if(misconception == 'Equiprobability'){
      return(
        `${Math.ceil(item.EResponseProp*100)}% (${(Math.ceil(item.EResponseProp* (this.state.need_support_equiprobability_open_blue.length+this.state.need_support_equiprobability_solid_blue.length)))} out of ${this.state.need_support_equiprobability_open_blue.length+this.state.need_support_equiprobability_solid_blue.length}) of students in the Need Support \n group selected an Everything Equally Likely Option`
      )
    }
    else{
      return (
        `${Math.ceil(item.IResponseProp*100)}% (${(Math.ceil(item.IResponseProp* (this.state.need_support_ignore_open_blue.length+this.state.need_support_ignore_solid_blue.length)))} out of ${this.state.need_support_ignore_open_blue.length+this.state.need_support_ignore_solid_blue.length}) of students in the Need Support \n group selected an Ignoring Independence Option`
      )
    }
  }


  render() {
    console.log(this.state);
    return (
      <div className="AppCopy">
        <main className="main-content bgc-grey-100">
          <div id="mainContent">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="bgc-white bdrs-3 p-20 mB-20">
                    <div className="row">
                      <div style={{ display: this.state.showDashboard == true ? 'block' : 'none' }}>
                        <h3 style={{ width: "100%" }}>
                          Welcome
                        </h3>
                        <br />
                        <p>
                          <br />
                          <br />
                          <b>Instructions for Teachers: </b>
                          <br />
	    		  <p>
	    		    Teachers please use the template from the DICE homepage to organize your data. You will need one datasheet for each of the two provided assessments: 1) Exploring Probability and 2) Chance and Data. Once you have entered your data into these templates, upload these excel templates into each of the two scoring pages by selecting "Results" then "Choose File". 
	Your classroom results will be provided to you in detail!
                          </p>
                        </p>
                        <p class="mt-30">
                          <b>Contact Us for Help:</b>
                          <br />
                          <p>
                            Use the email below if you have any questions about the research study, requirements, usernames or
                            passwords, or any other questions or concerns. Also use the email if you encounter any technical
                            difficulties, such as on-screen errors. For technical difficulties, please provide as many details
                            as possible. It is especially helpful if you can send a screenshot or take a picture with your
                            phone and include that in the email.
                          </p>
                          <p>
                            <a href="mailto:dice.research.project@gmail.com">dice.research.project@gmail.com</a>
                          </p>
                        </p>
                        <div class="row">
                          <div class="col-sm-6">
                            <div class="card">
                              <div class="card-body">
                                <h5 style={{ fontSize: "17.5px" }} class="card-title">Results: Exploring Probability</h5>
                                {/* <p class="card-text">With supporting text below as a natural lead-in to additional content.</p> */}
                                <a href="#" style={{ fontSize: "17.5px" }} onClick={this.exploringProbabilityShow} class="btn btn-primary">Results</a>
                              </div>
                            </div>
                            <div class="card">
                              <div class="card-body">
                                <h5 style={{ fontSize: "17.5px" }} class="card-title">Results: Chance And Data</h5>
                                {/* <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>  */}
                                <a href="#" style={{ fontSize: "17.5px" }} onClick={this.chanceAndDataShow} class="btn btn-primary">Results</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {this.state.exploringProbability == true ?
                        <>
                          <div className="col-md-10">
                            <h4 className="c-grey-900 mB-20">Results: Exploring Probability (Form 1.1)</h4>
                          </div>
                          <div className="col-md-2">
                            <div className="col-md-2">
                              <input type='file' onChange={this.importCSV} style={{ display: "block", margin: "10px auto" }} />
                            </div>
                          </div>

                        </>

                        :
                        ""}

                      {this.state.chanceAndData == true ?
                        <>
                          <ChanceAndData />
                        </> : ""}


                      {this.state.values.length > 0 ?

                        <>
                          <div className="row">
                            <ul className="nav nav-tabs new_nav_tab exploring_probability" role="tablist">
                              <li className="active col-md-2"><a data-toggle="tab" id="glanceTab" role="tab" href='#glance' aria-controls='glance'>At a
                                Glance</a></li>
                              <li className="col-md-4 equi_probability"><a data-toggle="tab" role="tab" id='equiprobabilityTab'
                                href="#equiprobability" aria-controls='equiprobability'>Misconception: Everything Equally Likely</a></li>
                              <li className="col-md-4 ignore_indepence "><a data-toggle="tab" role="tab"
                                href="#ignore" aria-controls='ignore' id='ignoreTab'>Misconception: Ignoring Independence</a></li>
                              <li className="col-md-2"><a data-toggle="tab" role="tab" href="#detailed_data" aria-controls='detailed_data' id='detailedDataTab'>Detailed
                                Data</a></li>
                            </ul>
                          </div>
                          <br />
                          <div className='tab-content'>
                            <div id="glance" className="tab-pane active  showtext-center mt-30" role='tabpanel' aria-labelledby='glanceTab'>
                              <div className="row mt-30">
                                <div className="col-md-5">
                                  Misconception: Everything Equally Likely

                                  <div className="progress">
                                    <div className="progress-bar need"
                                      style={{ width: `${Math.ceil(((this.state.need_support_equiprobability_open_blue.length + this.state.need_support_equiprobability_solid_blue.length) / this.state.values.length) * 100)}%` }}
                                      data-toggle="tooltip"
                                      title={Math.ceil(((this.state.need_support_equiprobability_open_blue.length + this.state.need_support_equiprobability_solid_blue.length) / this.state.values.length) * 100)}
                                      data-original-title={Math.ceil(((this.state.need_support_equiprobability_open_blue.length + this.state.need_support_equiprobability_solid_blue.length) / this.state.values.length) * 100)}
                                      data-placement="bottom" >
                                      {Math.ceil(((this.state.need_support_equiprobability_open_blue.length + this.state.need_support_equiprobability_solid_blue.length) / this.state.values.length) * 100)}%
                                    </div>
                                    <div className="progress-bar understand"
                                      style={{ width: `${Math.ceil(((this.state.does_not_need_support_equiprobability_solid_green.length + this.state.does_not_need_support_equiprobability_open_green.length) / this.state.values.length) * 100)}%` }}
                                      data-toggle="tooltip"
                                      title={Math.ceil(((this.state.does_not_need_support_equiprobability_solid_green.length + this.state.does_not_need_support_equiprobability_open_green.length) / this.state.values.length) * 100)}
                                      data-original-title={Math.ceil(((this.state.does_not_need_support_equiprobability_solid_green.length + this.state.does_not_need_support_equiprobability_open_green.length) / this.state.values.length) * 100)}>
                                      {Math.ceil(((this.state.does_not_need_support_equiprobability_solid_green.length + this.state.does_not_need_support_equiprobability_open_green.length) / this.state.values.length) * 100)}%
                                    </div>

                                    {/* <div className="progress-bar other" style={{ width: "3.7%", overflow: "initial" }}
                                      data-toggle="tooltip" title="3.7%" data-original-title="3.7%">
                                      3.7%
                                    </div> */}
                                  </div>
                                </div>
                                <div className="col-md-7 mt-2 text-left">
                                  <div className="ml-60">
                                    Everything Equally Likely: Believing that, no matter what the sample
                                    space is, all outcomes
                                    are equally likely to occur
                                  </div>
                                </div>
                              </div>

                              <div className="row mt-30">
                                <div className="col-md-5">
                                  Misconception: Ignoring Independence

                                  <div className="progress">
                                    <div className="progress-bar need"
                                      style={{ width: `${Math.ceil(((this.state.need_support_ignore_open_blue.length + this.state.need_support_ignore_solid_blue.length) / this.state.values.length) * 100)}%` }}
                                      data-toggle="tooltip" title={Math.ceil(((this.state.need_support_ignore_open_blue.length + this.state.need_support_ignore_solid_blue.length) / this.state.values.length) * 100)}
                                      data-original-title={Math.ceil(((this.state.need_support_ignore_open_blue.length + this.state.need_support_ignore_solid_blue.length) / this.state.values.length) * 100)}>
                                      {Math.ceil(((this.state.need_support_ignore_open_blue.length + this.state.need_support_ignore_solid_blue.length) / this.state.values.length) * 100)}%
                                    </div>
                                    <div className="progress-bar understand"
                                      style={{ width: `${Math.ceil(((this.state.does_not_need_support_ignore_solid_green.length + this.state.does_not_need_support_ignore_open_green.length) / this.state.values.length) * 100)}%` }}
                                      data-toggle="tooltip"
                                      title={Math.ceil(((this.state.does_not_need_support_ignore_solid_green.length + this.state.does_not_need_support_ignore_open_green.length) / this.state.values.length) * 100)}
                                      data-original-title={Math.ceil(((this.state.does_not_need_support_ignore_solid_green.length + this.state.does_not_need_support_ignore_open_green.length) / this.state.values.length) * 100)}>

                                      {Math.ceil(((this.state.does_not_need_support_ignore_solid_green.length + this.state.does_not_need_support_ignore_open_green.length) / this.state.values.length) * 100)}%
                                    </div>

                                    {/* <div className="progress-bar other" style={{ width: "3.7%", overflow: "initial" }}
                                      data-toggle="tooltip" title="3.7%" data-original-title="3.7%">
                                      3.7%
                                    </div> */}
                                  </div>
                                </div>
                                <div className="col-md-7 mt-2 text-left">
                                  <div className="ml-60">
                                    Ignoring Independence: Believing that past results from independent
                                    trials can influence the
                                    likelihood of future results&nbsp;occurring
                                  </div>
                                </div>
                              </div>

                              <div className="row mt-30">
                                <div className="ml-20">
                                  <div className="box" style={{ backgroundColor: "#2196f3ad" }}></div>
                                  Needs Support on Misconception
                                </div>

                              </div>
                              <div className="row mt-2">
                                <div className="ml-20">
                                  <div className="box" style={{ backgroundColor: "#0080008a" }}></div>
                                  Does Not Need Support on Misconception
                                </div>
                              </div>
                              {/* <div className="row mt-2">
                                <div className="ml-20">
                                  <div className="box" style={{ backgroundColor: "#ccc" }}></div>
                                  Not Completed Assessment
                                </div>
                              </div> */}
                            </div>

                            <div id="equiprobability" className="tab-pane" aria-labelledby='equiprobabilityTab'>

                              <div className="row mt-30">
                                <ul className="nav nav-tabs new_nav_tab" role="tablist">
                                  <li className="col-md-4 active"><a data-toggle="tab" role="tab"
                                    href="#student_groups" className="btn" id='studentGroupsTab' aria-controls='student_groups'>Who
                                    Needs Support</a></li>

                                  <li className="col-md-4"><a data-toggle="tab" role="tab" href="#items" aria-controls='items' id='itemsTab'
                                    className="btn" >Support: Review the
                                    Misconception<br /> Responses</a></li>

                                  <li className="col-md-4"><a data-toggle="tab" role="tab" href="#activities" aria-controls='activities' id='activitiesTab'
                                    className="btn" >Support: Classroom
                                    Activities</a></li>

                                </ul>
                              </div>
                              <br />

                              <div className="tab-content report_tabs">
                                <div id="student_groups" className="tab-pane active" aria-labelledby='studentGroupsTab'>

                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="row circle-note">
                                        <h4>Instructional Support Groups</h4>
                                        Based on the assessment results, each student is grouped
                                        into their most likely instructional group: Needs Support or
                                        Does Not Need Support. A filled in circle indicates stronger
                                        evidence for the group assignment.
                                      </div>

                                      <div className="row">
                                        <div className="col-md-4 bound-student-list">
                                          <div className="student_head">
                                            <b>Needs Support on <i>Everything Equally
                                              Likely</i></b><br />
                                            ({this.state.need_support_equiprobability_solid_blue.length + this.state.need_support_equiprobability_open_blue.length} students)
                                          </div>

                                          <p>
                                          </p>
                                          {this.state.need_support_equiprobability_solid_blue.map((item) => {
                                            return (
                                              <div>
                                                <div className="box need"></div>
                                                {item}

                                                <p></p>
                                                <p>
                                                </p>
                                              </div>
                                            );
                                          })
                                          }
                                          {
                                            this.state.need_support_equiprobability_open_blue.map((item) => {
                                              return (
                                                <div>
                                                  <div className="blue_box_stripes"></div>

                                                  {item}
                                                  <p></p>
                                                  <p>
                                                  </p>
                                                </div>
                                              );
                                            })
                                          }

                                        </div>

                                        <div className="col-md-4 bound-student-list">
                                          <div className="student_head">
                                            <b>Does Not Need Support on <i>Everything Equally
                                              Likely</i></b> <br />
                                            ({this.state.does_not_need_support_equiprobability_open_green.length + this.state.does_not_need_support_equiprobability_solid_green.length} students)
                                          </div>



                                          <p>
                                          </p>
                                          {
                                            this.state.does_not_need_support_equiprobability_solid_green.map((item) => {
                                              return (
                                                <div>
                                                  <div className="box understand"></div>

                                                  {item}
                                                  <p></p>

                                                  <p>
                                                  </p>
                                                </div>
                                              );

                                            })
                                          }
                                          {
                                            this.state.does_not_need_support_equiprobability_open_green.map((item) => {
                                              return (
                                                <div>
                                                  <div className="green_box_stripes"></div>

                                                  {item}
                                                  <p></p>

                                                  <p>
                                                  </p>
                                                </div>
                                              );

                                            })
                                          }

                                        </div>

                                        <div className="col-md-3 bound-student-list incomplete">
                                          <div className="student_head">
                                            <b>Incomplete Assessment</b><br />
                                            (0 students)
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div id="activities" className="tab-pane mt-30" aria-labelledby='activitiesTab'>
                                  <h4>Activities for Targeted Learning Related to <em>Everything Equally
                                    Likely (EEL)</em></h4>

                                  <p>
                                    Activities provided here are designed to assist students in
                                    confronting a bias which involves assigning
                                    equal probabilities to events that are not equally likely to occur.
                                    Students possessing the bias might
                                    provide different types of reasons that they assign equal chances
                                    for outcomes that are not equally likely.
                                  </p>

                                  <p>
                                    For example, consider a random-generating context that includes
                                    binary results (e.g., win or lose). A
                                    student reasoning that <em>Everything is Equally Likely</em> [EEL]
                                    argues that since there are only two possible
                                    outcomes, the chances are equal or “50-50” for each outcome of win
                                    and lose.
                                  </p>

                                  <p>
                                    This type of reasoning is not limited to only binary outcomes. If
                                    the sample space includes only two categories
                                    of elements, then students possessing the bias might assign equal
                                    chances to each category. For instance,
                                    consider a box that has four Red and nine Blue marbles, then an EEL
                                    reasoner would consider the chance of
                                    Blue and Red marbles equal as there are only two colors and
                                    therefore they believe that each color of marble
                                    will have a 50% chance of being randomly chosen.
                                  </p>

                                  <p>
                                    Students may also exhibit EEL reasoning if there is a situation with
                                    a random generating device and they <em>want</em> a
                                    particular outcome. Even if there are many possible outcomes, the
                                    student may say that the desired outcome has
                                    a 50% chance because either that outcome will occur or not. For
                                    example, with a roll of a fair die, if the
                                    student desires to roll a six, they will say they have a 50% chance
                                    of getting a six, because they can either
                                    roll a six or not roll a six. They reduce the sample space with six
                                    elements [1, 2, 3, 4, 5, 6] to have only
                                    2 elements [6, not 6]. Instead of estimating that each of the six
                                    numbers has an equal chance to occur [1/6],
                                    they assign 1/2 to their sample space of [6, 6].
                                  </p>

                                  <p>
                                    Activity 1: <a href="./assets/userDownload/Everything Equally Likely_Activity 1_Blue or Red.pdf"
                                    download
                                      target="_blank">Blue or Red?</a>
                                  </p>

                                  <p>
                                    Activity 2: <a href="./assets/userDownload/Everything Equally Likely_Activity 2_Wheel of Colors.pdf" download
                                      target="_blank">Wheel of Colors</a>
                                  </p>

                                  <p>
                                    Activity 3: <a href="./assets/userDownload/Everything Equally Likely_Activity 3_Game Compass.pdf" download
                                      target="_blank">Game Compass</a>
                                  </p>

                                  <hr />

                                  <h4>Additional Readings and Resources for Teaching Probability</h4>

                                  <p>
                                    <a href="./assets/userDownload/DICE_Free_Resources_for_Teaching_Probability_in_Middle_School.pdf" download
                                      target="_blank">This PDF document</a> contains
                                    several readings, lesson plans, and online apps that can expand your
                                    instructional repertoire
                                    for teaching probability concepts in middle school. The link to this
                                    document is available
                                    under each of the Support: Classroom Activities tab for
                                    misconceptions on Form 1 (Exploring
                                    Probability) and Form 2 (Chance and Data).
                                  </p>
                                </div>

                                <br />

                                <div id="items" className="tab-pane" aria-labelledby='itemsTab'>
                                  <h4>Misconception&nbsp;Responses</h4>
                                  <div>
                                    <p>
                                      This page lists the questions that measure
                                      the&nbsp;<em>Everything Equally Likely</em>&nbsp;misconception.
                                      Each question has
                                      a ring chart showing the&nbsp;<span
                                        style={{ color: "#2196f3ad" }}><strong>percentage of students in
                                          the “needs support”
                                          group</strong></span>&nbsp;who selected a misconception
                                      response option, which is a response option indicative of
                                      misconception&nbsp;reasoning. Hover over the purple part of the
                                      ring to see the student counts.
                                    </p>

                                    <p>
                                      The letter(s) inside the rings show the misconception response
                                      option(s) for that question. Below each
                                      ring is one or more examples of the kinds of student reasoning
                                      that might lead a student to select the
                                      misconception response option. The examples are drawn from the
                                      DICE researchers’ interviews with students.
                                    </p>

                                    <p>
                                      The questions are listed in order of the percentage, from high
                                      to low. If you plan to review these
                                      questions with students, the questions at the top of the list
                                      may be most useful to review.
                                    </p>
                                  </div>

                                  <div className="dotted_line pdb-50"></div>
                                  {this.state.equiprobabilityPercent.map((item, index) => {
                                    return (
                                      <div
                                        className="row mt-30 dotted_line pdb-50">
                                        <div
                                          className="col-md-6">
                                          <div
                                            className="row">
                                            <div
                                              className="col-md-12 ml-22 mt-1 font-18">
                                              <b>
                                              </b>
                                            </div>

                                            <div
                                              className="col-md-12">
                                              <span
                                                className="learnosity-item"
                                                data-reference="{ item }"><img alt='' src={this.showQuestionEquiprobability(item.ItemID)} style={{ height: "100%", width: "120%" }} /></span>
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          className="col-md-6" style={{ paddingLeft: "100px" }}>
                                          {this.showExampleStudentReasoningEquiprobability(item, "Equiprobability")}
                                        </div>
                                      </div>

                                    )
                                  })
                                  }

                                </div>
                              </div>
                            </div>

                            <div id="ignore" className="tab-pane">
                              <div className="row mt-30">
                                <ul className="nav nav-tabs new_nav_tab" role="tablist">
                                  <li className="col-md-4 active"><a data-toggle="tab" role="tab" aria-expanded="true"
                                    href="#student_groups_ignore" className="btn">Who Needs Support</a></li>

                                  <li className="col-md-4"><a data-toggle="tab" role="tab" href="#items_ignore" aria-expanded="false"
                                    className="btn">Support: Review the Misconception<br />Responses</a>
                                  </li>

                                  <li className="col-md-4"><a data-toggle="tab" role="tab" aria-expanded="false"
                                    href="#activities_ignore" className="btn">Support: Classroom
                                    Activities</a></li>
                                </ul>
                              </div>
                              <br />

                              <div className="tab-content report_tabs">
                                <div id="student_groups_ignore" className="tab-pane active">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="row circle-note">
                                        <h4>Instructional Support Groups</h4>
                                        Based on the assessment results, each student is grouped into
                                        their most likely instructional group: Needs Support or Does Not
                                        Need Support. A filled in circle indicates stronger evidence for
                                        the group assignment.
                                      </div>

                                      <div className="row">
                                        <div className="col-md-4 bound-student-list">
                                          <div className="student_head">
                                            <b>Needs Support on <i>Ignoring Independence</i></b>
                                            <br />
                                            ({this.state.need_support_ignore_open_blue.length + this.state.need_support_ignore_solid_blue.length} students)
                                          </div>
                                          {this.state.need_support_ignore_solid_blue.map((item) => {
                                            return (
                                              <div>
                                                <div className="box need"></div>
                                                {item}

                                                <p></p>
                                                <p>
                                                </p>
                                              </div>
                                            );
                                          })
                                          }
                                          {
                                            this.state.need_support_ignore_open_blue.map((item) => {
                                              return (
                                                <div>
                                                  <div className="blue_box_stripes"></div>

                                                  {item}
                                                  <p></p>
                                                  <p>
                                                  </p>
                                                </div>
                                              );
                                            })
                                          }
                                        </div>


                                        <div className="col-md-4 bound-student-list">
                                          <div className="student_head">
                                            <b>Does Not Need Support on <i>Ignoring
                                              Independence</i></b><br />
                                            ({this.state.does_not_need_support_ignore_open_green.length + this.state.does_not_need_support_ignore_solid_green.length} students)
                                          </div>
                                          {
                                            this.state.does_not_need_support_ignore_solid_green.map((item) => {
                                              return (
                                                <div>
                                                  <div className="box understand"></div>

                                                  {item}
                                                  <p></p>

                                                  <p>
                                                  </p>
                                                </div>
                                              );

                                            })
                                          }
                                          {
                                            this.state.does_not_need_support_ignore_open_green.map((item) => {
                                              return (
                                                <div>
                                                  <div className="green_box_stripes"></div>

                                                  {item}
                                                  <p></p>

                                                  <p>
                                                  </p>
                                                </div>
                                              );

                                            })
                                          }

                                        </div>

                                        <div className="col-md-3 bound-student-list incomplete">
                                          <div className="student_head">
                                            <b>Incomplete Assessment</b><br />
                                            {/* ({other_users.length} students) */}
                                          </div>
                                          {/* {  other_users.each do |user| } */}
                                          <p>
                                            {/* {user.short_name} */}
                                          </p>
                                          {/* {end} */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>


                                <div id="activities_ignore" className="tab-pane mt-30">


                                  <h4>Activities for Targeted Learning Related to <em>Ignoring
                                    Independence</em></h4>

                                  <p>
                                    Activities provided here are designed to assist students in confronting
                                    a bias towards considering how past
                                    results from a random-generating context influences the probability of
                                    future results. In essence, students
                                    reasoning with <em>Ignoring Independence</em> do not attend to how each
                                    random-generating process (like a single die
                                    roll or coin flip) produces results that are independent of each other.
                                    The key concept students for
                                    students to learn through these activities is that in a
                                    random-generating context, there should be no
                                    predictability of single outcomes in the short term (i.e., what will
                                    happen next) or of a distinct pattern
                                    (e.g., TTHTTH means T should occur next to keep the pattern of TTH).
                                    Students often struggle with this
                                    concept because they (correctly) expect to be able to predict long-term
                                    trends in the relative frequency
                                    of outcomes (i.e., after many coin flips we should expect about 50% to
                                    be heads), and they want to apply
                                    this notion of predictability to short-term or single outcomes.
                                  </p>

                                  <p>
                                    The suggested activities are meant to give students an experience with
                                    short-term and long-term behavior with
                                    random-generating contexts. Students will observe how short-term
                                    outcomes that may appear to follow a
                                    specific pattern do not impact future outcomes and that specific
                                    patterns in results from random-generating
                                    contexts are not more likely to occur than others.
                                  </p>

                                  <p>
                                    Activity 1: <a href="./assets/userDownload/Ignoring Independence_Activity 1_Tails or Heads.pdf" download
                                      target="_blank">Tails or Heads?</a>
                                  </p>
                                  <p>
                                    Activity 2: <a href="./assets/userDownload/Ignoring Independence_Activity 2_Bag of Balls.pdf" download target="_blank">Bag
                                      of Balls</a>
                                  </p>
                                  <p>
                                    Activity 3: <a href="./assets/userDownload/Ignoring Independence_Activity 3_Spinner Next Spin.pdf" download
                                      target="_blank">Spinner Next Spin</a>
                                  </p>

                                  <hr />

                                  <h4>Additional Readings and Resources for Teaching Probability</h4>

                                  <p>
                                    <a href="./assets/userDownload/DICE_Free_Resources_for_Teaching_Probability_in_Middle_School.pdf" download
                                      target="_blank">This PDF document</a> contains
                                    several readings, lesson plans, and online apps that can expand your
                                    instructional repertoire
                                    for teaching probability concepts in middle school. The link to this
                                    document is available
                                    under each of the Support: Classroom Activities tab for misconceptions
                                    on Form 1 (Exploring
                                    Probability) and Form 2 (Chance and Data).
                                  </p>
                                </div>

                                <br />

                                <div id="items_ignore" className="tab-pane">
                                  <h4>Misconception Responses</h4>
                                  <div>
                                    <p>
                                      This page lists the questions that measure the <em>Ignoring
                                        Independence</em> misconception. Each question has
                                      a ring chart showing the <span
                                        style={{ color: "#2196f3ad" }}><strong>percentage of students in the
                                          “needs support”
                                          group</strong></span> who selected a misconception response
                                      option, which is a response option indicative of
                                      misconception reasoning. Hover over the purple part of the ring to
                                      see the student counts.
                                    </p>

                                    <p>
                                      The letter(s) inside the rings show the misconception response
                                      option(s) for that question. Below each
                                      ring is one or more examples of the kinds of student reasoning that
                                      might lead a student to select the
                                      misconception response option. The examples are drawn from the DICE
                                      researchers’ interviews with students.
                                    </p>

                                    <p>
                                      The questions are listed in order of the percentage, from high to
                                      low. If you plan to review these
                                      questions with students, the questions at the top of the list may be
                                      most useful to review.
                                    </p>
                                  </div>


                                  <div className="dotted_line pdb-50"></div>
                                  {this.state.ignoreprobabilityPercent.map((item, index) => {
                                    return (
                                      <div
                                        className="row mt-30 dotted_line pdb-50">
                                        <div
                                          className="col-md-6">
                                          <div
                                            className="row"

                                          >
                                            <div
                                              className="col-md-12 ml-22 mt-1 font-18">
                                              <b>
                                              </b>
                                            </div>

                                            <div
                                              className="col-md-12">
                                              <span
                                                className="learnosity-item"
                                                data-reference="{ item }"><img alt='' src={this.showQuestionEquiprobability(item.ItemID)} style={{ height: "100%", width: "120%" }} /></span>
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          className="col-md-6" style={{ paddingLeft: "100px" }}>
                                          {this.showExampleStudentReasoningEquiprobability(item, "Ignore")}
                                        </div>
                                      </div>
                                    )

                                  })
                                  }
                                </div>
                              </div>
                            </div>



                            <div id="detailed_data" className="tab-pane">
                              <div className="row mt-30">
                                <ul className="nav nav-tabs font-16 new_nav_tab" role="tablist">
                                  <li className="active col-md-4"><a data-toggle="tab" role="tab"
                                    href="#res1_rep" className="btn">Responses Report</a></li>

                                  <li className="col-md-4"><a data-toggle="tab" role="tab"
                                    href="#misconception1" className="btn">Misconceptions Report</a>
                                  </li>

                                  {/* <li className="col-md-4"><a data-toggle="tab" role="tab" href="#scored1"
                                    className="btn">Scored Report</a></li> */}
                                </ul>
                              </div>

                              <div className="tab-content detailed_report_tabs mt-30">
                                <div id="res1_rep" className="tab-pane active">
                                  <div id="table-scroll" className="table-scroll">
                                    <table
                                      className="table class_results table-striped table-bordered main-table"
                                      id="main-table" style={{ width: "auto" }}>
                                      <thead>
                                        <tr>
                                          <th colSpan={this.state.tableRows.length - 5}
                                            className="text-center">
                                            RESPONSES REPORT
                                          </th>
                                          <th colSpan="5" className="text-center">
                                          </th>
                                        </tr>
                                        <tr>
                                          <th colSpan="8" className="text-center">
                                            Teacher
                                          </th>
                                          <th colSpan="11" className="text-center">KEY:
                                            A/B/C/D/E=Selected Response; S=Skipped</th>
                                        </tr>

                                        <tr>
                                          <th style={{ top: "0px" }} colSpan="4" className="text-center">Student Info
                                          </th>
                                          <th colSpan={this.state.tableRows.length - 3} style={{ top: "0px" }}
                                            className="text-center">Questions</th>
                                        </tr>
                                        <tr className="resp_report">
                                          <th style={{ top: "0px" }}>#</th>
                                          <th style={{ top: "0px" }}><span
                                            style={{ padding: "2px", whitespace: "nowrap" }}>Username</span>
                                          </th>
                                          <th style={{ top: "0px" }}><span
                                            style={{ padding: "2px", whitespace: "nowrap" }}>First</span>
                                          </th>
                                          <th style={{ top: "0px" }}><span
                                            style={{ padding: "6px", whitespace: "nowrap" }}>Last</span>
                                          </th>
                                          {this.state.tableRows.slice(3, 18).map((row, i) =>
                                            <th className="set_extra" style={{ top: "0px" }}>
                                              {row}
                                            </th>
                                          )}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.exploringProbability == true ?
                                          this.state.values.map((row, i) => {
                                            return (
                                              < tr className="resp_report">
                                                <td>{i + 1}</td>
                                                <td>{row[0]}</td>
                                                <td>{row[1]}</td>
                                                <td>{row[2]}</td>
                                                <td>{row[3]}</td>
                                                <td>{row[4]}</td>
                                                <td>{row[5]}</td>
                                                <td>{row[6]}</td>
                                                <td>{row[7]}</td>
                                                <td>{row[8]}</td>
                                                <td>{row[9]}</td>
                                                <td>{row[10]}</td>
                                                <td>{row[11]}</td>
                                                <td>{row[12]}</td>
                                                <td>{row[13]}</td>
                                                <td>{row[14]}</td>
                                                <td>{row[15]}</td>
                                                <td>{row[16]}</td>
                                                <td>{row[17]}</td>
                                              </tr>
                                            )

                                          }) : ""}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>

                                <div id="misconception1" className="tab-pane">
                                  <div id="table-scroll" className="table-scroll">
                                    <table className="table class_results table-striped table-bordered"
                                      id="tblData" style={{ width: "auto" }}>
                                      <thead>

                                        <tr>
                                          <th colSpan="20" className="text-center">
                                            MISCONCEPTION REPORT
                                          </th>

                                          <th colSpan="6" className="text-center">
                                          </th>
                                        </tr>
                                        <tr>
                                          <th colSpan="7" className="text-center">
                                            Teacher
                                          </th>
                                          <th colSpan="10" className="text-center">
                                            Form 1.1
                                          </th>
                                          <th colSpan="11" className="text-center">
                                            <div>KEY: C=Correct; E=Everything Equally
                                              Likely; I=Ignoring Independence; </div>
                                            <div>O=Other; S=Skipped; "-"=This question
                                              does not measure this misconception
                                            </div>
                                          </th>
                                        </tr>


                                        <tr className="perc_m1 grayed">
                                          <td rowSpan="6" colSpan="3"
                                            className="vert-head text-center">
                                            <b>Question Level Summaries</b>
                                          </td>
                                          <td><b>Total C</b></td>
                                          {this.state.exploringProbability == true ?
                                            this.state.columnDetailedDataTab.length > 0 ?
                                              <>
                                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountC[0].Q1}</b></td>
                                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountC[0].Q2}</b></td>
                                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountC[0].Q3}</b></td>
                                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountC[0].Q4}</b></td>
                                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountC[0].Q5}</b></td>
                                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountC[0].Q6}</b></td>
                                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountC[0].Q7}</b></td>
                                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountC[0].Q8}</b></td>
                                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountC[0].Q9}</b></td>
                                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountC[0].Q10}</b></td>
                                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountC[0].Q11}</b></td>
                                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountC[0].Q12}</b></td>
                                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountC[0].Q13}</b></td>
                                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountC[0].Q14}</b></td>
                                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountC[0].Q15}</b></td>
                                              </>
                                              :
                                              <td></td>
                                            : ""
                                          }
                                          <td rowSpan="6" colSpan="8"
                                            className="grayed"></td>
                                        </tr>
                                        <tr className="perc_m1">
                                          <td className="bb3p"><b>Total %C</b></td>
                                          {this.state.exploringProbability == true ?
                                            this.state.columnDetailedDataTab.length > 0 ?
                                              <>
                                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountC[0].Q1) * 100) / this.state.values.length)}</b></td>
                                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountC[0].Q2) * 100) / this.state.values.length)}</b></td>
                                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountC[0].Q3) * 100) / this.state.values.length)}</b></td>
                                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountC[0].Q4) * 100) / this.state.values.length)}</b></td>
                                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountC[0].Q5) * 100) / this.state.values.length)}</b></td>
                                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountC[0].Q6) * 100) / this.state.values.length)}</b></td>
                                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountC[0].Q7) * 100) / this.state.values.length)}</b></td>
                                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountC[0].Q8) * 100) / this.state.values.length)}</b></td>
                                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountC[0].Q9) * 100) / this.state.values.length)}</b></td>
                                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountC[0].Q10) * 100) / this.state.values.length)}</b></td>
                                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountC[0].Q11) * 100) / this.state.values.length)}</b></td>
                                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountC[0].Q12) * 100) / this.state.values.length)}</b></td>
                                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountC[0].Q13) * 100) / this.state.values.length)}</b></td>
                                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountC[0].Q14) * 100) / this.state.values.length)}</b></td>
                                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountC[0].Q15) * 100) / this.state.values.length)}</b></td>
                                              </>
                                              :
                                              <td></td>
                                            : ""
                                          }
                                        </tr>
                                        <tr className="perc_m1 grayed">
                                          <td><b>Total E</b></td>
                                          {this.state.exploringProbability == true ?
                                            this.state.columnDetailedDataTab.length > 0 ?
                                              <>
                                                {/* <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountE[0].Q1}</b></td>
                          <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountE[0].Q2}</b></td> */}
                                                <td className="bb3p"><b>-</b></td>
                                                <td className="bb3p"><b>-</b></td>
                                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountE[0].Q3}</b></td>
                                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountE[0].Q4}</b></td>
                                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountE[0].Q5}</b></td>
                                                {/* <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountE[0].Q6}</b></td>
                          <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountE[0].Q7}</b></td> */}
                                                <td className="bb3p"><b>-</b></td>
                                                <td className="bb3p"><b>-</b></td>
                                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountE[0].Q8}</b></td>
                                                {/* <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountE[0].Q9}</b></td> */}
                                                <td className="bb3p"><b>-</b></td>
                                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountE[0].Q10}</b></td>
                                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountE[0].Q11}</b></td>
                                                {/* <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountE[0].Q12}</b></td> */}
                                                <td className="bb3p"><b>-</b></td>
                                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountE[0].Q13}</b></td>
                                                {/* <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountE[0].Q14}</b></td> */}
                                                <td className="bb3p"><b>-</b></td>
                                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountE[0].Q15}</b></td>
                                              </>
                                              :
                                              <td></td>
                                            : ""
                                          }
                                        </tr>
                                        <tr className="perc_m1">
                                          <td className="bb3p"><b>Total %E</b></td>
                                          {this.state.exploringProbability == true ?
                                            this.state.columnDetailedDataTab.length > 0 ?
                                              <>
                                                <td className="bb3p"><b>-</b></td>
                                                <td className="bb3p"><b>-</b></td>
                                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountE[0].Q3) * 100) / this.state.values.length)}</b></td>
                                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountE[0].Q4) * 100) / this.state.values.length)}</b></td>
                                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountE[0].Q5) * 100) / this.state.values.length)}</b></td>
                                                <td className="bb3p"><b>-</b></td>
                                                <td className="bb3p"><b>-</b></td>
                                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountE[0].Q8) * 100) / this.state.values.length)}</b></td>
                                                <td className="bb3p"><b>-</b></td>
                                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountE[0].Q10) * 100) / this.state.values.length)}</b></td>
                                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountE[0].Q11) * 100) / this.state.values.length)}</b></td>
                                                <td className="bb3p"><b>-</b></td>
                                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountE[0].Q13) * 100) / this.state.values.length)}</b></td>
                                                <td className="bb3p"><b>-</b></td>
                                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountE[0].Q15) * 100) / this.state.values.length)}</b></td>
                                              </>
                                              :
                                              <td></td>
                                            : ""
                                          }
                                        </tr>
                                        <tr className="perc_m1 grayed">
                                          <td><b>Total I</b></td>
                                          {this.state.exploringProbability == true ?
                                            this.state.columnDetailedDataTab.length > 0 ?
                                              <>
                                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountI[0].Q1}</b></td>
                                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountI[0].Q2}</b></td>
                                                <td className="bb3p"><b>-</b></td>
                                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountI[0].Q4}</b></td>
                                                <td className="bb3p"><b>-</b></td>
                                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountI[0].Q6}</b></td>
                                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountI[0].Q7}</b></td>
                                                <td className="bb3p"><b>-</b></td>
                                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountI[0].Q9}</b></td>
                                                <td className="bb3p"><b>-</b></td>
                                                <td className="bb3p"><b>-</b></td>
                                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountI[0].Q12}</b></td>
                                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountI[0].Q13}</b></td>
                                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountI[0].Q14}</b></td>
                                                <td className="bb3p"><b>-</b></td>
                                              </>
                                              :
                                              <td></td>
                                            : ""
                                          }
                                        </tr>

                                        <tr className="perc_m1">
                                          <td className="bb3p"><b>Total %I</b></td>
                                          {this.state.exploringProbability == true ?
                                            this.state.columnDetailedDataTab.length > 0 ?
                                              <>
                                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountI[0].Q1) * 100) / this.state.values.length)}</b></td>
                                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountI[0].Q2) * 100) / this.state.values.length)}</b></td>
                                                <td className="bb3p"><b>-</b></td>
                                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountI[0].Q4) * 100) / this.state.values.length)}</b></td>
                                                <td className="bb3p"><b>-</b></td>
                                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountI[0].Q6) * 100) / this.state.values.length)}</b></td>
                                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountI[0].Q7) * 100) / this.state.values.length)}</b></td>
                                                <td className="bb3p"><b>-</b></td>
                                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountI[0].Q9) * 100) / this.state.values.length)}</b></td>
                                                <td className="bb3p"><b>-</b></td>
                                                <td className="bb3p"><b>-</b></td>
                                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountI[0].Q12) * 100) / this.state.values.length)}</b></td>
                                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountI[0].Q13) * 100) / this.state.values.length)}</b></td>
                                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountI[0].Q14) * 100) / this.state.values.length)}</b></td>
                                                <td className="bb3p"><b>-</b></td>
                                              </>
                                              :
                                              <td></td>
                                            : ""
                                          }
                                        </tr>

                                        <tr>
                                          <th colSpan="4" className="text-center">
                                            Student Info</th>
                                          <th colSpan={this.state.tableRows.length - 3}
                                            className="text-center">Questions</th>
                                          <th colSpan="9" className="text-center">
                                            Student Summaries</th>
                                        </tr>
                                        <tr>
                                          <th>#</th>
                                          <th><span
                                            style={{ padding: "2px", whitespace: "nowrap" }}>Username</span>
                                          </th>
                                          <th><span
                                            style={{ padding: "2px", whitespace: "nowrap" }}>First</span>
                                          </th>
                                          <th><span
                                            style={{ padding: "16px", whitespace: "nowrap" }}>Last</span>
                                          </th>
                                          {/* {items.length.times do |i| } */}
                                          {this.state.tableRows.slice(3, 18).map((row, i) =>
                                            <th className="set_extra" style={{ top: "0px" }}>
                                              {row}
                                            </th>
                                          )}
                                          {/* <th
                      className="set_extra { (i ==  items.length-1) ? 'bw2p' : '' }">
                      {/* {(i + 1).to_s} 
                    </th> */}
                                          <th className="set_width"><span
                                            style={{ padding: "2px", whitespace: "nowrap" }}
                                            data-toggle='tooltip'
                                            title='This is the number of questions that the student answered correctly.'>Total
                                            C</span></th>
                                          <th className="set_width bw2p"><span
                                            style={{ padding: "2px", whitespace: "nowrap" }}
                                            data-toggle='tooltip'
                                            title='This is the percent of questions that the student answered correctly. Note: The test had 15 questions.'>C%
                                            (Total C/15)</span></th>
                                          <th className="set_width"><span
                                            style={{ padding: "4px", whitespace: "nowrap" }}
                                            data-toggle='tooltip'
                                            title='This is the number of questions for which the student selected an Everything Equally Likely response option.'>Total
                                            E</span></th>
                                          <th className="set_width bw2p"><span
                                            style={{ padding: "4px", whitespace: "nowrap" }}
                                            data-toggle='tooltip'
                                            title='This is the percent of questions for which the student selected an Everything Equally Likely response option. Note: The test had 8 questions that had an Everything Equally Likely response option.'>%E
                                            (Total E/8)</span></th>
                                          <th className="set_width"><span
                                            style={{ padding: "4px", whitespace: "nowrap" }}
                                            data-toggle='tooltip'
                                            title='This is the number of questions for which the student selected an Ignoring Independence response option.'>Total
                                            I</span></th>
                                          <th className="set_width bw2p"><span
                                            style={{ padding: "4px", whitespace: "nowrap" }}
                                            data-toggle='tooltip'
                                            title='This is the percent of questions for which the student selected an Ignoring Independence response option. Note: The test had 9 questions that had an Ignoring Independence response option.'>I%
                                            (Total I/9)</span></th>
                                          <th><span
                                            style={{ padding: "2px", whitespace: "nowrap" }}>First</span>
                                          </th>
                                          <th className="bw2p"><span
                                            style={{ padding: "6px", whitespace: "nowrap" }}>Last</span>
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.exploringProbability == true ?
                                          this.state.misconceptionMatrix.map((row, i) => {
                                            return (
                                              < tr className="resp_report">
                                                <td>{i + 1}</td>
                                                <td>{row[0].Username}</td>
                                                <td>{row[1].Firstname}</td>
                                                <td>{row[2].Lastname}</td>
                                                <td>{row[0].Q1}</td>
                                                <td>{row[1].Q2}</td>
                                                <td>{row[2].Q3}</td>
                                                <td>{row[3].Q4}</td>
                                                <td>{row[4].Q5}</td>
                                                <td>{row[5].Q6}</td>
                                                <td>{row[6].Q7}</td>
                                                <td>{row[7].Q8}</td>
                                                <td>{row[8].Q9}</td>
                                                <td>{row[9].Q10}</td>
                                                <td>{row[10].Q11}</td>
                                                <td>{row[11].Q12}</td>
                                                <td>{row[12].Q13}</td>
                                                <td>{row[13].Q14}</td>
                                                <td className="bw2p"><b>{row[14].Q15}</b></td>
                                                <td><b>{row[15].C}</b></td>
                                                <td className="bw2p"><b>{Math.ceil(((row[15].C) * 100) / 15)}</b></td>
                                                <td><b>{row[15].E}</b></td>
                                                <td className="bw2p"><b>{Math.ceil(((row[15].E) * 100) / 8)}</b></td>
                                                <td><b>{row[15].I}</b></td>
                                                <td className="bw2p"><b>{Math.ceil(((row[15].I) * 100) / 9)}</b></td>
                                                <td><b>{row[1].Firstname}</b></td>
                                                <td className="bw2p"><b>{row[2].Lastname}</b></td>
                                              </tr>
                                            )
                                          }) : ""}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>

                                <div id="scored1" className="tab-pane fade">
                                  <div id="table-scroll1" className="table-scroll">
                                    <table className="table class_results table-striped table-bordered"
                                      id="tblData" style={{ width: "auto" }}>
                                      <thead>
                                        <tr>
                                          <th colSpan="17" className="text-center">
                                            SCORED REPORT
                                          </th>

                                          <th colSpan="5" className="text-center">
                                          </th>
                                        </tr>
                                        <tr>
                                          <th colSpan="5" className="text-center">
                                          </th>
                                          <th colSpan="7" className="text-center">
                                          </th>
                                          <th colSpan="10" className="text-center">
                                            <div>KEY: 1=Correct Response; 0=Incorrect
                                              Response </div><br />
                                          </th>
                                        </tr>

                                        <tr>
                                          <th colSpan="4" className="text-center">Student Info
                                          </th>
                                          <th colSpan="18" className="text-center">Questions
                                          </th>
                                        </tr>
                                        <tr className="resp_report">
                                          <th>#</th>
                                          <th><span
                                            style={{ padding: "2px", whitespace: "nowrap" }}>Username</span>
                                          </th>
                                          <th><span
                                            style={{ padding: "2px", whitespace: "nowrap" }}>First</span>
                                          </th>
                                          <th><span
                                            style={{ padding: "6px", whitespace: "nowrap" }}>Last</span>
                                          </th>
                                          <th className="set_extra">
                                          </th>

                                          <th className="perc_c">%C</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td className="text-center" colSpan="6">No record
                                            found</td>
                                        </tr>
                                        <tr className="resp_report1">
                                          <td colSpan="4" className="text-center">
                                            <strong>%C</strong>
                                          </td>
                                        </tr>
                                        <tr className="resp_report">
                                          {/* {i = i + 1} */}
                                          <td>
                                            {/* {i} */}
                                          </td>
                                          <td>
                                            {/* {all_users[key].try(:user_name)
                                                                                        } */}
                                          </td>
                                          <td>
                                            {/* {all_users[key].try(:first_name)
                                                                                        } */}
                                          </td>
                                          <td>
                                            {/* {all_users[key].try(:last_name)
                                                                                        } */}
                                          </td>

                                          {/* {15.times do |i| } */}
                                          <td>
                                            {/* {value[(i + 1)].present ?
                                                                                            ?
                  (value[(i + 1)])
                  : '0' } */}
                                          </td>
                                          {/* {end} */}
                                          <td
                                            className="perc_c">
                                            <strong>
                                              {/* {((100
                  *
                  value.values.count(1).to_f) /
                  15.0).round(1)
                } */}
                                            </strong>
                                          </td>
                                        </tr>
                                        {/* {end} */}

                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </> : ""
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main >
      </div >
    );
  }
}
const mapStatetoProps = state => {
  return {
    parsedData: state.parsedData,
    tableRows: state.tableRows,
    values: state.values
  };
};
export default connect(mapStatetoProps)(AppCopy);
// export default App;
