import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AppCopy from './App_copy';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
// import { store } from './store';

const root = ReactDOM.createRoot(document.getElementById('root'));
const store = createStore(() => ({
  login: [
    {}
  ],
  parsedData: [],
  tableRows: [],
  values: [],
  classification: [],
  need_support_equiprobability_solid_blue: [],
  need_support_equiprobability_open_blue: [],
  does_not_need_support_equiprobability_solid_green: [],
  does_not_need_support_equiprobability_open_green: [],
  need_support_ignore_solid_blue: [],
  need_support_ignore_open_blue: [],
  does_not_need_support_ignore_open_green: [],
  does_not_need_support_ignore_solid_green: [],
  incomplete_equiprobability: [],
  imcomplete_ignore: [],
  misconception: [],
  misconceptionMatrix: [],
  scoreMatrix: [],
  columnDetailedDataTab: []
}));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <AppCopy />
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
