import logo from './logo.svg';
import './App.css';
import './main.css';
import './custom.css';
import { connect } from 'react-redux';
import Papa from 'papaparse';
import React, { Component } from "react";
import * as XLSX from 'xlsx';
import classification from './misconception_classification.csv';
import misconception from './misconception.csv';
import { grinTongueSquint } from 'fontawesome';
import { faBriefcaseClock } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';
import { Tooltip } from 'react-tooltip';
import bg from './assets/images/bg.jpg';
import login from './login.csv';
import { FlippingCard, FlippingCardBack, FlippingCardFront, UserCard } from 'react-ui-cards';
import axios from 'axios';
import { CircularProgressbar, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Q1 from './assets/images/ChanceAndDataQuestions/Question 1.jpg';
import Q2 from './assets/images/ChanceAndDataQuestions/Question 2.jpg';
import Q3 from './assets/images/ChanceAndDataQuestions/Question 3.jpg';
import Q4 from './assets/images/ChanceAndDataQuestions/Question 4.jpg';
import Q5 from './assets/images/ChanceAndDataQuestions/Question 5.jpg';
import Q6 from './assets/images/ChanceAndDataQuestions/Question 6.jpg';
import Q7 from './assets/images/ChanceAndDataQuestions/Question 7.jpg';
import Q8 from './assets/images/ChanceAndDataQuestions/Question 8.jpg';
import Q9 from './assets/images/ChanceAndDataQuestions/Question 9.jpg';
import Q10 from './assets/images/ChanceAndDataQuestions/Question 10.jpg';
import Q11 from './assets/images/ChanceAndDataQuestions/Question 11.jpg';
import Q12 from './assets/images/ChanceAndDataQuestions/Question 12.jpg';
import Q13 from './assets/images/ChanceAndDataQuestions/Question 13.jpg';
import Q14 from './assets/images/ChanceAndDataQuestions/Question 14.jpg';
import exploringProbabilityStudentReasoning from './chanceAndData.json';

class ChanceAndData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parsedData: [],
      tableRows: [],
      values: [],
      classification: [],
      need_support_equiprobability_solid_blue: [],
      need_support_equiprobability_open_blue: [],
      does_not_need_support_equiprobability_solid_green: [],
      does_not_need_support_equiprobability_open_green: [],
      need_support_ignore_solid_blue: [],
      need_support_ignore_open_blue: [],
      does_not_need_support_ignore_open_green: [],
      does_not_need_support_ignore_solid_green: [],
      incomplete_equiprobability: [],
      imcomplete_ignore: [],
      misconception: [],
      misconceptionMatrix: [],
      scoreMatrix: [],
      columnDetailedDataTab: [],
      email: "",
      password: "",
      cpassword: "",
      firstName: "",
      lastName: "",
      loggedIn: false,
      loginData: [],
      loginErrorDisplay: false,
      exploringProbability: false,
      chanceAndData: false,
      showDashboard: true,
      createAccountShow: false,
      equiprobabilityPercent: [],
      ignoreprobabilityPercent: [],
      APIresponseMisconceptionData: [],

    };
    this.importCSV = this.importCSV.bind(this);
    this.updateData = this.updateData.bind(this);

  }

  importCSV = (event) => {
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: this.updateData
    });
    if (event.target.files[0]) {
      const formData = new FormData();
      formData.append('file', event.target.files[0]);
      axios
        //.post('http://localhost:8080/uploadChanceAndData', formData)
        .post('http://dice.coe.uga.edu/uploadChanceAndData', formData)
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }

  }

  updateData = (results) => {
    let rowsArray = [];
    let valuesArray = [];

    // Iterating data to get column name and their values
    results.data.map((d) => {
      rowsArray.push(Object.keys(d));
      valuesArray.push(Object.values(d));
    });
    // console.log(results.data);
    this.setState({
      parsedData: results.data,
      tableRows: rowsArray[0],
      values: valuesArray
    });

    //let url = 'http://127.0.0.1:8000/predict_chance_and_data';
    let url = 'http://dice.coe.uga.edu/predict_chance_and_data';
    let data = results.data;

    fetch(url, {
      method: 'POST',
      // mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        // Add any additional headers if required
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(result => {
        // Handle the response result
        console.log(result);
        this.setState({
          equiprobabilityPercent: result.iePercents,
          ignoreprobabilityPercent: result.irfPercents,
          APIresponseMisconceptionData: result.studentResponses
        })
        this.getClassificationEquiprobability(result.studentResponses);
        this.getClassificationIgnore(result.studentResponses);
      })
      .catch(error => {
        // Handle any errors that occurred during the request
        console.error('Error:', error);
      });

    this.getMisconceptionMatrix(results.data);

  }


  getClassificationData = async () => {
    let response = await fetch(classification)
    let reader = response.body.getReader()
    let result = await reader.read() // raw array
    let decoder = new TextDecoder('utf-8')
    let csv = decoder.decode(result.value) // the csv text
    let results = Papa.parse(csv, { header: true }) // object with { data, errors, meta }
    let rows = results.data // array of objects
    this.setState({ classification: rows });
    // this.getClassificationEquiprobability();
    // this.getClassificationIgnore();
  }

  getMisconceptionData = async () => {
    let response = await fetch(misconception)
    let reader = response.body.getReader()
    let result = await reader.read() // raw array
    let decoder = new TextDecoder('utf-8')
    let csv = decoder.decode(result.value) // the csv text
    let results = Papa.parse(csv, { header: true }) // object with { data, errors, meta }
    let rows = results.data // array of objects
    this.setState({ misconception: rows });
  }

  getMisconceptionMatrix = (parsedData) => {
    let misconceptionMatrix = [];
    let scoreMatrix = [];
    parsedData.forEach((item1, index1) => {
      let rowMatrix = [];
      let score = [];
      let countC = 0;
      let countI = 0;
      let countE = 0;
      let countO = 0;
      this.state.misconception.forEach((item2, index2) => {
        if (item2.Form == 2 && item2.Item == 'Q1' && item2.Response == item1.Q1) {
          rowMatrix.push({ 'Q1': item2.Misconception, 'Username': item1.Username, 'Firstname': item1.Firstname, 'Lastname': item1.Lastname })
          score.push({ 'Q1': item2.Score })
          if (item2.Misconception == 'C') {
            countC++;
          }
          else if (item2.Misconception == 'R') {
            countI++;
          }
          else if (item2.Misconception == 'P') {
            countE++;
          }
          else if (item2.Misconception == 'O') {
            countO++;
          }
        }
        else if (item2.Form == 2 && item2.Item == 'Q2' && item2.Response == item1.Q2) {
          rowMatrix.push({ 'Q2': item2.Misconception, 'Username': item1.Username, 'Firstname': item1.Firstname, 'Lastname': item1.Lastname })
          score.push({ 'Q2': item2.Score })
          if (item2.Misconception == 'C') {
            countC++;
          }
          else if (item2.Misconception == 'R') {
            countI++;
          }
          else if (item2.Misconception == 'P') {
            countE++;
          }
          else if (item2.Misconception == 'O') {
            countO++;
          }
        }
        else if (item2.Form == 2 && item2.Item == 'Q3' && item2.Response == item1.Q3) {
          rowMatrix.push({ 'Q3': item2.Misconception, 'Username': item1.Username, 'Firstname': item1.Firstname, 'Lastname': item1.Lastname })
          score.push({ 'Q3': item2.Score })
          if (item2.Misconception == 'C') {
            countC++;
          }
          else if (item2.Misconception == 'R') {
            countI++;
          }
          else if (item2.Misconception == 'P') {
            countE++;
          }
          else if (item2.Misconception == 'O') {
            countO++;
          }
        }
        else if (item2.Form == 2 && item2.Item == 'Q4' && item2.Response == item1.Q4) {
          rowMatrix.push({ 'Q4': item2.Misconception, 'Username': item1.Username, 'Firstname': item1.Firstname, 'Lastname': item1.Lastname })
          score.push({ 'Q4': item2.Score })
          if (item2.Misconception == 'C') {
            countC++;
          }
          else if (item2.Misconception == 'R') {
            countI++;
          }
          else if (item2.Misconception == 'P') {
            countE++;
          }
          else if (item2.Misconception == 'O') {
            countO++;
          }
        }
        else if (item2.Form == 2 && item2.Item == 'Q5' && item2.Response == item1.Q5) {
          rowMatrix.push({ 'Q5': item2.Misconception, 'Username': item1.Username, 'Firstname': item1.Firstname, 'Lastname': item1.Lastname })
          score.push({ 'Q5': item2.Score })
          if (item2.Misconception == 'C') {
            countC++;
          }
          else if (item2.Misconception == 'R') {
            countI++;
          }
          else if (item2.Misconception == 'P') {
            countE++;
          }
          else if (item2.Misconception == 'O') {
            countO++;
          }
        }
        else if (item2.Form == 2 && item2.Item == 'Q6' && item2.Response == item1.Q6) {
          rowMatrix.push({ 'Q6': item2.Misconception, 'Username': item1.Username, 'Firstname': item1.Firstname, 'Lastname': item1.Lastname })
          score.push({ 'Q6': item2.Score })
          if (item2.Misconception == 'C') {
            countC++;
          }
          else if (item2.Misconception == 'R') {
            countI++;
          }
          else if (item2.Misconception == 'P') {
            countE++;
          }
          else if (item2.Misconception == 'O') {
            countO++;
          }
        }
        else if (item2.Form == 2 && item2.Item == 'Q7' && item2.Response == item1.Q7) {
          rowMatrix.push({ 'Q7': item2.Misconception, 'Username': item1.Username, 'Firstname': item1.Firstname, 'Lastname': item1.Lastname })
          score.push({ 'Q7': item2.Score })
          if (item2.Misconception == 'C') {
            countC++;
          }
          else if (item2.Misconception == 'R') {
            countI++;
          }
          else if (item2.Misconception == 'P') {
            countE++;
          }
          else if (item2.Misconception == 'O') {
            countO++;
          }
        }
        else if (item2.Form == 2 && item2.Item == 'Q8' && item2.Response == item1.Q8) {
          rowMatrix.push({ 'Q8': item2.Misconception, 'Username': item1.Username, 'Firstname': item1.Firstname, 'Lastname': item1.Lastname })
          score.push({ 'Q8': item2.Score })
          if (item2.Misconception == 'C') {
            countC++;
          }
          else if (item2.Misconception == 'R') {
            countI++;
          }
          else if (item2.Misconception == 'P') {
            countE++;
          }
          else if (item2.Misconception == 'O') {
            countO++;
          }
        }
        else if (item2.Form == 2 && item2.Item == 'Q9' && item2.Response == item1.Q9) {
          rowMatrix.push({ 'Q9': item2.Misconception, 'Username': item1.Username, 'Firstname': item1.Firstname, 'Lastname': item1.Lastname })
          score.push({ 'Q9': item2.Score })
          if (item2.Misconception == 'C') {
            countC++;
          }
          else if (item2.Misconception == 'R') {
            countI++;
          }
          else if (item2.Misconception == 'P') {
            countE++;
          }
          else if (item2.Misconception == 'O') {
            countO++;
          }
        }
        else if (item2.Form == 2 && item2.Item == 'Q10' && item2.Response == item1.Q10) {
          rowMatrix.push({ 'Q10': item2.Misconception, 'Username': item1.Username, 'Firstname': item1.Firstname, 'Lastname': item1.Lastname })
          score.push({ 'Q10': item2.Score })
          if (item2.Misconception == 'C') {
            countC++;
          }
          else if (item2.Misconception == 'R') {
            countI++;
          }
          else if (item2.Misconception == 'P') {
            countE++;
          }
          else if (item2.Misconception == 'O') {
            countO++;
          }
        }
        else if (item2.Form == 2 && item2.Item == 'Q11' && item2.Response == item1.Q11) {
          rowMatrix.push({ 'Q11': item2.Misconception, 'Username': item1.Username, 'Firstname': item1.Firstname, 'Lastname': item1.Lastname })
          score.push({ 'Q11': item2.Score })
          if (item2.Misconception == 'C') {
            countC++;
          }
          else if (item2.Misconception == 'R') {
            countI++;
          }
          else if (item2.Misconception == 'P') {
            countE++;
          }
          else if (item2.Misconception == 'O') {
            countO++;
          }
        }
        else if (item2.Form == 2 && item2.Item == 'Q12' && item2.Response == item1.Q12) {
          rowMatrix.push({ 'Q12': item2.Misconception, 'Username': item1.Username, 'Firstname': item1.Firstname, 'Lastname': item1.Lastname })
          score.push({ 'Q12': item2.Score })
          if (item2.Misconception == 'C') {
            countC++;
          }
          else if (item2.Misconception == 'R') {
            countI++;
          }
          else if (item2.Misconception == 'P') {
            countE++;
          }
          else if (item2.Misconception == 'O') {
            countO++;
          }
        }
        else if (item2.Form == 2 && item2.Item == 'Q13' && item2.Response == item1.Q13) {
          rowMatrix.push({ 'Q13': item2.Misconception, 'Username': item1.Username, 'Firstname': item1.Firstname, 'Lastname': item1.Lastname })
          score.push({ 'Q13': item2.Score })
          if (item2.Misconception == 'C') {
            countC++;
          }
          else if (item2.Misconception == 'R') {
            countI++;
          }
          else if (item2.Misconception == 'P') {
            countE++;
          }
          else if (item2.Misconception == 'O') {
            countO++;
          }
        }
        else if (item2.Form == 2 && item2.Item == 'Q14' && item2.Response == item1.Q14) {
          rowMatrix.push({ 'Q14': item2.Misconception, 'Username': item1.Username, 'Firstname': item1.Firstname, 'Lastname': item1.Lastname })
          score.push({ 'Q14': item2.Score })
          if (item2.Misconception == 'C') {
            countC++;
          }
          else if (item2.Misconception == 'R') {
            countI++;
          }
          else if (item2.Misconception == 'P') {
            countE++;
          }
          else if (item2.Misconception == 'O') {
            countO++;
          }
        }
      })

      rowMatrix.push({ 'C': countC, 'I': countI, 'E': countE, 'O': countO })
      misconceptionMatrix.push(rowMatrix);
      scoreMatrix.push(score);
    })
    this.setState({
      misconceptionMatrix: misconceptionMatrix,
      scoreMatrix: scoreMatrix
    })
    this.getDetailedDataPercentages(misconceptionMatrix);
  }

  getClassificationIgnore = (studentResponses) => {
    let solid_green = [];
    let open_green = [];
    let solid_blue = [];
    let open_blue = [];

    studentResponses.forEach((item, index) => {
      switch (item.IgnoringRelativeFrequency_FINAL) {
        case "Solid green": solid_green.push(item.Username); break;
        case "Solid blue": solid_blue.push(item.Username); break;
        case "Open green": open_green.push(item.Username); break;
        case "Open blue": open_blue.push(item.Username); break;
      }
    })
    this.setState({
      does_not_need_support_ignore_solid_green: solid_green,
      need_support_ignore_solid_blue: solid_blue,
      need_support_ignore_open_blue: open_blue,
      does_not_need_support_ignore_open_green: open_green,
    })
  }

  getClassificationEquiprobability = (studentResponses) => {
    let solid_green = [];
    let open_green = [];
    let solid_blue = [];
    let open_blue = [];

    studentResponses.forEach((item, index) => {
      switch (item.IllusionOfEquality_FINAL) {
        case "Solid green": solid_green.push(item.Username); break;
        case "Solid blue": solid_blue.push(item.Username); break;
        case "Open green": open_green.push(item.Username); break;
        case "Open blue": open_blue.push(item.Username); break;
      }
    })
    this.setState({
      does_not_need_support_equiprobability_solid_green: solid_green,
      need_support_equiprobability_solid_blue: solid_blue,
      need_support_equiprobability_open_blue: open_blue,
      does_not_need_support_equiprobability_open_green: open_green,
    })
  }

  getDetailedDataPercentages = (matrix) => {
    let totalCountC = [{
      Q1: 0,
      Q2: 0,
      Q3: 0,
      Q4: 0,
      Q5: 0,
      Q6: 0,
      Q7: 0,
      Q8: 0,
      Q9: 0,
      Q10: 0,
      Q11: 0,
      Q12: 0,
      Q13: 0,
      Q14: 0,
      Q15: 0
    }];
    let totalCountE = [{
      Q1: 0,
      Q2: 0,
      Q3: 0,
      Q4: 0,
      Q5: 0,
      Q6: 0,
      Q7: 0,
      Q8: 0,
      Q9: 0,
      Q10: 0,
      Q11: 0,
      Q12: 0,
      Q13: 0,
      Q14: 0,
      Q15: 0
    }];
    let totalCountI = [{
      Q1: 0,
      Q2: 0,
      Q3: 0,
      Q4: 0,
      Q5: 0,
      Q6: 0,
      Q7: 0,
      Q8: 0,
      Q9: 0,
      Q10: 0,
      Q11: 0,
      Q12: 0,
      Q13: 0,
      Q14: 0,
      Q15: 0
    }];
    matrix.forEach((item, index1) => {
      switch (item[0].Q1) {
        case 'R': totalCountI[0].Q1 = totalCountI[0].Q1 + 1; break;
        case 'P': totalCountE[0].Q1 = totalCountE[0].Q1 + 1; break;
        case 'C': totalCountC[0].Q1 = totalCountC[0].Q1 + 1; break;
      }
      switch (item[1].Q2) {
        case 'R': totalCountI[0].Q2 = totalCountI[0].Q2 + 1; break;
        case 'P': totalCountE[0].Q2 = totalCountE[0].Q2 + 1; break;
        case 'C': totalCountC[0].Q2 = totalCountC[0].Q2 + 1; break;
      }switch (item[2].Q3) {
        case 'R': totalCountI[0].Q3 = totalCountI[0].Q3 + 1; break;
        case 'P': totalCountE[0].Q3 = totalCountE[0].Q3 + 1; break;
        case 'C': totalCountC[0].Q3 = totalCountC[0].Q3 + 1; break;
      }switch (item[3].Q4) {
        case 'R': totalCountI[0].Q4 = totalCountI[0].Q4 + 1; break;
        case 'P': totalCountE[0].Q4 = totalCountE[0].Q4 + 1; break;
        case 'C': totalCountC[0].Q4 = totalCountC[0].Q4 + 1; break;
      }switch (item[4].Q5) {
        case 'R': totalCountI[0].Q5 = totalCountI[0].Q5 + 1; break;
        case 'P': totalCountE[0].Q5 = totalCountE[0].Q5 + 1; break;
        case 'C': totalCountC[0].Q5 = totalCountC[0].Q5 + 1; break;
      }switch (item[5].Q6) {
        case 'R': totalCountI[0].Q6 = totalCountI[0].Q6 + 1; break;
        case 'P': totalCountE[0].Q6 = totalCountE[0].Q6 + 1; break;
        case 'C': totalCountC[0].Q6 = totalCountC[0].Q6 + 1; break;
      }switch (item[6].Q7) {
        case 'R': totalCountI[0].Q7 = totalCountI[0].Q7 + 1; break;
        case 'P': totalCountE[0].Q7 = totalCountE[0].Q7 + 1; break;
        case 'C': totalCountC[0].Q7 = totalCountC[0].Q7 + 1; break;
      }switch (item[7].Q8) {
        case 'R': totalCountI[0].Q8 = totalCountI[0].Q8 + 1; break;
        case 'P': totalCountE[0].Q8 = totalCountE[0].Q8 + 1; break;
        case 'C': totalCountC[0].Q8 = totalCountC[0].Q8 + 1; break;
      }switch (item[8].Q9) {
        case 'R': totalCountI[0].Q9 = totalCountI[0].Q9 + 1; break;
        case 'P': totalCountE[0].Q9 = totalCountE[0].Q9 + 1; break;
        case 'C': totalCountC[0].Q9 = totalCountC[0].Q9 + 1; break;
      }switch (item[9].Q10) {
        case 'R': totalCountI[0].Q10 = totalCountI[0].Q10 + 1; break;
        case 'P': totalCountE[0].Q10 = totalCountE[0].Q10 + 1; break;
        case 'C': totalCountC[0].Q10 = totalCountC[0].Q10 + 1; break;
      }switch (item[10].Q11) {
        case 'R': totalCountI[0].Q11 = totalCountI[0].Q11 + 1; break;
        case 'P': totalCountE[0].Q11 = totalCountE[0].Q11 + 1; break;
        case 'C': totalCountC[0].Q11 = totalCountC[0].Q11 + 1; break;
      }switch (item[11].Q12) {
        case 'R': totalCountI[0].Q12 = totalCountI[0].Q12 + 1; break;
        case 'P': totalCountE[0].Q12 = totalCountE[0].Q12 + 1; break;
        case 'C': totalCountC[0].Q12 = totalCountC[0].Q12 + 1; break;
      }switch (item[12].Q13) {
        case 'R': totalCountI[0].Q13 = totalCountI[0].Q13 + 1; break;
        case 'P': totalCountE[0].Q13 = totalCountE[0].Q13 + 1; break;
        case 'C': totalCountC[0].Q13 = totalCountC[0].Q13 + 1; break;
      }switch (item[13].Q14) {
        case 'R': totalCountI[0].Q14 = totalCountI[0].Q14 + 1; break;
        case 'P': totalCountE[0].Q14 = totalCountE[0].Q14 + 1; break;
        case 'C': totalCountC[0].Q14 = totalCountC[0].Q14 + 1; break;
      }
    })
    let detailedDataColumn = []
    detailedDataColumn.push({ 'colCountC': totalCountC, 'colCountE': totalCountE, 'colCountI': totalCountI });
    this.setState({ columnDetailedDataTab: detailedDataColumn });
  }


  percentColC = () => {
    if (this.state.values > 0) {
      this.state.columnDetailedDataTab[0].colCountC[0].map((item, index) => {
        return (
          <>
            <td className="bb3p"><b>{Math.ceil(((item.Q1) * 100) / this.state.values.length)}</b></td>
            <td className="bb3p"><b>{Math.ceil(((item.Q2) * 100) / this.state.values.length)}</b></td>
            <td className="bb3p"><b>{Math.ceil(((item.Q3) * 100) / this.state.values.length)}</b></td>
            <td className="bb3p"><b>{Math.ceil(((item.Q4) * 100) / this.state.values.length)}</b></td>
            <td className="bb3p"><b>{Math.ceil(((item.Q5) * 100) / this.state.values.length)}</b></td>
            <td className="bb3p"><b>{Math.ceil(((item.Q6) * 100) / this.state.values.length)}</b></td>
            <td className="bb3p"><b>{Math.ceil(((item.Q7) * 100) / this.state.values.length)}</b></td>
            <td className="bb3p"><b>{Math.ceil(((item.Q8) * 100) / this.state.values.length)}</b></td>
            <td className="bb3p"><b>{Math.ceil(((item.Q9) * 100) / this.state.values.length)}</b></td>
            <td className="bb3p"><b>{Math.ceil(((item.Q10) * 100) / this.state.values.length)}</b></td>
            <td className="bb3p"><b>{Math.ceil(((item.Q11) * 100) / this.state.values.length)}</b></td>
            <td className="bb3p"><b>{Math.ceil(((item.Q12) * 100) / this.state.values.length)}</b></td>
            <td className="bb3p"><b>{Math.ceil(((item.Q13) * 100) / this.state.values.length)}</b></td>
            <td className="bb3p"><b>{Math.ceil(((item.Q14) * 100) / this.state.values.length)}</b></td>
            <td className="bb3p"><b>{Math.ceil(((item.Q15) * 100) / this.state.values.length)}</b></td>
          </>
        )
      })
    }
    else {
      return (
        <td></td>
      )
    }
  }

  exploringProbabilityShow = () => {
    this.setState({ exploringProbability: true, showDashboard: false })
  }

  chanceAndDataShow = () => {
    this.setState({ chanceAndData: true, showDashboard: false })
  }

  componentDidMount() {
    this.getClassificationData();
    this.getMisconceptionData();

    //fetch('http://127.0.0.1:8000/health-check', {
    fetch('http://dice.coe.uga.edu/health-check', {
      method: 'GET',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json',
        // Add any additional headers if required
      },
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  showQuestionEquiprobability = (ItemID) => {
    switch (ItemID) {
      case "Q1": return Q1; break;
      case "Q2": return Q2; break;
      case "Q3": return Q3; break;
      case "Q4": return Q4; break;
      case "Q5": return Q5; break;
      case "Q6": return Q6; break;
      case "Q7": return Q7; break;
      case "Q8": return Q8; break;
      case "Q9": return Q9; break;
      case "Q10": return Q10; break;
      case "Q11": return Q11; break;
      case "Q12": return Q12; break;
      case "Q13": return Q13; break;
      case "Q14": return Q14; break;
    }
  }

  findItemById = (arr, id) => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].id == id) {
        return arr[i];
      }
    }
  }

  showExampleStudentReasoningEquiprobability = (item, misconception) => {
    let foundJsonItem = this.findItemById(exploringProbabilityStudentReasoning, item.ItemID);

    return (
      <>
        <div style={{ maxWidth: "70%", maxHeight: "70%", alignContent: "center", whiteSpace: "pre-wrap" }}
          title={this.showTooltipForProgressBar(item, misconception)}
        >
          <CircularProgressbarWithChildren value={Math.ceil((misconception === "Equiprobability" ? item.IEResponseProp : item.IRFResponseProp) * 100)}>
            <div style={{ fontSize: "30px", color: "#337ab7" }}>
              {Math.ceil((misconception === "Equiprobability" ? item.IEResponseProp : item.IRFResponseProp) * 100)}% <br />
              {foundJsonItem.text}
            </div>
          </CircularProgressbarWithChildren>
        </div>
        <br /><br />
        <h3><strong>Example Student Reasoning</strong></h3>
        {foundJsonItem.misconception}
      </>
    );
  }

  showTooltipForProgressBar = (item, misconception) => {
    if (misconception == 'Equiprobability') {
      return (
        `${Math.ceil(item.IEResponseProp * 100)}% (${(Math.ceil(item.IEResponseProp * (this.state.need_support_equiprobability_open_blue.length + this.state.need_support_equiprobability_solid_blue.length)))} out of ${this.state.need_support_equiprobability_open_blue.length + this.state.need_support_equiprobability_solid_blue.length}) of students in the Need Support \n group selected an Illusion of Equality Option`
      )
    }
    else {
      return (
        `${Math.ceil(item.IRFResponseProp * 100)}% (${(Math.ceil(item.IRFResponseProp * (this.state.need_support_ignore_open_blue.length + this.state.need_support_ignore_solid_blue.length)))} out of ${this.state.need_support_ignore_open_blue.length + this.state.need_support_ignore_solid_blue.length}) of students in the Need Support \n group selected an Ignoring Relative Frequency Option`
      )
    }
  }


  render() {
    console.log(this.state);
    return (
      <div>
        <div className="col-md-10">
          <h4 className="c-grey-900 mB-20">Results: Chance and Data</h4>
        </div>
        <div className="col-md-2">
          <div className="col-md-2">
            <input type='file' onChange={this.importCSV} style={{ display: "block", margin: "10px auto" }} />
          </div>
        </div>
        {this.state.values.length > 0 ?

          <>
            <div className="row">
              <ul className="nav nav-tabs new_nav_tab exploring_probability" role="tablist">
                <li className="active col-md-2"><a data-toggle="tab" id="glanceTab" role="tab" href='#glance' aria-controls='glance'>At a
                  Glance</a></li>
                <li className="col-md-4 equi_probability"><a data-toggle="tab" role="tab" id='equiprobabilityTab'
                  href="#equiprobability" aria-controls='equiprobability'>Misconception: Illusion of Equality</a></li>
                <li className="col-md-4 ignore_indepence "><a data-toggle="tab" role="tab"
                  href="#ignore" aria-controls='ignore' id='ignoreTab'>Misconception: Ignoring Relative Frequency</a></li>
                <li className="col-md-2"><a data-toggle="tab" role="tab" href="#detailed_data" aria-controls='detailed_data' id='detailedDataTab'>Detailed
                  Data</a></li>
              </ul>
            </div>
            <br />
            <div className='tab-content'>
              <div id="glance" className="tab-pane active  showtext-center mt-30" role='tabpanel' aria-labelledby='glanceTab'>
                <div className="row mt-30">
                  <div className="col-md-5">
                    Misconception: Illusion of Equality

                    <div className="progress">
                      <div className="progress-bar need"
                        style={{ width: `${Math.ceil(((this.state.need_support_equiprobability_open_blue.length + this.state.need_support_equiprobability_solid_blue.length) / this.state.values.length) * 100)}%` }}
                        data-toggle="tooltip"
                        title={Math.ceil(((this.state.need_support_equiprobability_open_blue.length + this.state.need_support_equiprobability_solid_blue.length) / this.state.values.length) * 100)}
                        data-original-title={Math.ceil(((this.state.need_support_equiprobability_open_blue.length + this.state.need_support_equiprobability_solid_blue.length) / this.state.values.length) * 100)}
                        data-placement="bottom" >
                        {Math.ceil(((this.state.need_support_equiprobability_open_blue.length + this.state.need_support_equiprobability_solid_blue.length) / this.state.values.length) * 100)}%
                      </div>
                      <div className="progress-bar understand"
                        style={{ width: `${Math.ceil(((this.state.does_not_need_support_equiprobability_solid_green.length + this.state.does_not_need_support_equiprobability_open_green.length) / this.state.values.length) * 100)}%` }}
                        data-toggle="tooltip"
                        title={Math.ceil(((this.state.does_not_need_support_equiprobability_solid_green.length + this.state.does_not_need_support_equiprobability_open_green.length) / this.state.values.length) * 100)}
                        data-original-title={Math.ceil(((this.state.does_not_need_support_equiprobability_solid_green.length + this.state.does_not_need_support_equiprobability_open_green.length) / this.state.values.length) * 100)}>
                        {Math.ceil(((this.state.does_not_need_support_equiprobability_solid_green.length + this.state.does_not_need_support_equiprobability_open_green.length) / this.state.values.length) * 100)}%
                      </div>

                      {/* <div className="progress-bar other" style={{ width: "3.7%", overflow: "initial" }}
                                      data-toggle="tooltip" title="3.7%" data-original-title="3.7%">
                                      3.7%
                                    </div> */}
                    </div>
                  </div>
                  <div className="col-md-7 mt-2 text-left">
                    <div className="ml-60">
                      Illusion of Equality: Considering empirical results with similar relative frequencies to be equally <br></br>
                      likely to occur, even though the data was collected with different sample sizes <br></br>
                      (e.g., 10 coin flips versus 80 coin flips)
                    </div>
                  </div>
                </div>

                <div className="row mt-30">
                  <div className="col-md-5">
                    Misconception: Ignoring Relative Frequency

                    <div className="progress">
                      <div className="progress-bar need"
                        style={{ width: `${Math.ceil(((this.state.need_support_ignore_open_blue.length + this.state.need_support_ignore_solid_blue.length) / this.state.values.length) * 100)}%` }}
                        data-toggle="tooltip" title={Math.ceil(((this.state.need_support_ignore_open_blue.length + this.state.need_support_ignore_solid_blue.length) / this.state.values.length) * 100)}
                        data-original-title={Math.ceil(((this.state.need_support_ignore_open_blue.length + this.state.need_support_ignore_solid_blue.length) / this.state.values.length) * 100)}>
                        {Math.ceil(((this.state.need_support_ignore_open_blue.length + this.state.need_support_ignore_solid_blue.length) / this.state.values.length) * 100)}%
                      </div>
                      <div className="progress-bar understand"
                        style={{ width: `${Math.ceil(((this.state.does_not_need_support_ignore_solid_green.length + this.state.does_not_need_support_ignore_open_green.length) / this.state.values.length) * 100)}%` }}
                        data-toggle="tooltip"
                        title={Math.ceil(((this.state.does_not_need_support_ignore_solid_green.length + this.state.does_not_need_support_ignore_open_green.length) / this.state.values.length) * 100)}
                        data-original-title={Math.ceil(((this.state.does_not_need_support_ignore_solid_green.length + this.state.does_not_need_support_ignore_open_green.length) / this.state.values.length) * 100)}>

                        {Math.ceil(((this.state.does_not_need_support_ignore_solid_green.length + this.state.does_not_need_support_ignore_open_green.length) / this.state.values.length) * 100)}%
                      </div>

                      {/* <div className="progress-bar other" style={{ width: "3.7%", overflow: "initial" }}
                                      data-toggle="tooltip" title="3.7%" data-original-title="3.7%">
                                      3.7%
                                    </div> */}
                    </div>
                  </div>
                  <div className="col-md-7 mt-2 text-left">
                    <div className="ml-60">
                      Ignoring Relative Frequency: Ignoring the relative frequencies of possible outcomes <br></br> when comparing sample spaces and making probability judgments by only comparing frequencies

                    </div>
                  </div>
                </div>

                <div className="row mt-30">
                  <div className="ml-20">
                    <div className="box" style={{ backgroundColor: "#2196f3ad" }}></div>
                    Needs Support on Misconception
                  </div>

                </div>
                <div className="row mt-2">
                  <div className="ml-20">
                    <div className="box" style={{ backgroundColor: "#0080008a" }}></div>
                    Does Not Need Support on Misconception
                  </div>
                </div>
                {/* <div className="row mt-2">
                                <div className="ml-20">
                                  <div className="box" style={{ backgroundColor: "#ccc" }}></div>
                                  Not Completed Assessment
                                </div>
                              </div> */}
              </div>

              <div id="equiprobability" className="tab-pane" aria-labelledby='equiprobabilityTab'>

                <div className="row mt-30">
                  <ul className="nav nav-tabs new_nav_tab" role="tablist">
                    <li className="col-md-4 active"><a data-toggle="tab" role="tab"
                      href="#student_groups" className="btn" id='studentGroupsTab' aria-controls='student_groups'>Who
                      Needs Support</a></li>

                    <li className="col-md-4"><a data-toggle="tab" role="tab" href="#items" aria-controls='items' id='itemsTab'
                      className="btn" >Support: Review the
                      Misconception<br /> Responses</a></li>

                    <li className="col-md-4"><a data-toggle="tab" role="tab" href="#activities" aria-controls='activities' id='activitiesTab'
                      className="btn" >Support: Classroom
                      Activities</a></li>

                  </ul>
                </div>
                <br />

                <div className="tab-content report_tabs">
                  <div id="student_groups" className="tab-pane active" aria-labelledby='studentGroupsTab'>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="row circle-note">
                          <h4>Instructional Support Groups</h4>
                          Based on the assessment results, each student is grouped
                          into their most likely instructional group: Needs Support or
                          Does Not Need Support. A filled in circle indicates stronger
                          evidence for the group assignment.
                        </div>

                        <div className="row">
                          <div className="col-md-4 bound-student-list">
                            <div className="student_head">
                              <b>Needs Support on <i>Everything Equally
                                Likely</i></b><br />
                              ({this.state.need_support_equiprobability_solid_blue.length + this.state.need_support_equiprobability_open_blue.length} students)
                            </div>

                            <p>
                            </p>
                            {this.state.need_support_equiprobability_solid_blue.map((item) => {
                              return (
                                <div>
                                  <div className="box need"></div>
                                  {item}

                                  <p></p>
                                  <p>
                                  </p>
                                </div>
                              );
                            })
                            }
                            {
                              this.state.need_support_equiprobability_open_blue.map((item) => {
                                return (
                                  <div>
                                    <div className="blue_box_stripes"></div>

                                    {item}
                                    <p></p>
                                    <p>
                                    </p>
                                  </div>
                                );
                              })
                            }

                          </div>

                          <div className="col-md-4 bound-student-list">
                            <div className="student_head">
                              <b>Does Not Need Support on <i>Everything Equally
                                Likely</i></b> <br />
                              ({this.state.does_not_need_support_equiprobability_open_green.length + this.state.does_not_need_support_equiprobability_solid_green.length} students)
                            </div>



                            <p>
                            </p>
                            {
                              this.state.does_not_need_support_equiprobability_solid_green.map((item) => {
                                return (
                                  <div>
                                    <div className="box understand"></div>

                                    {item}
                                    <p></p>

                                    <p>
                                    </p>
                                  </div>
                                );

                              })
                            }
                            {
                              this.state.does_not_need_support_equiprobability_open_green.map((item) => {
                                return (
                                  <div>
                                    <div className="green_box_stripes"></div>

                                    {item}
                                    <p></p>

                                    <p>
                                    </p>
                                  </div>
                                );

                              })
                            }

                          </div>

                          <div className="col-md-3 bound-student-list incomplete">
                            <div className="student_head">
                              <b>Incomplete Assessment</b><br />
                              (0 students)
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="activities" className="tab-pane mt-30" aria-labelledby='activitiesTab'>
                    <div class="col-md-12">
                      <div class="row circle-note">
                        <h4>Activities for Targeted Learning Related to <em>Illusion of Equality (IOE)</em></h4>

                        <p>
                          Activities provided here are designed to assist students in confronting a misconception that occurs
                          when students are given empirical data of two possible results (typically with very different sample
                          sizes) and they make a judgement that the resulting events are equally likely to occur. For example,
                          thinking that 3 heads in 5 fair coin tosses is just as likely to happen as getting 300 heads in 500
                          tosses is reasoning with the illusion of equality. This illusion that two empirical results have the
                          same likelihood of occuring might be based on several different kinds of reasoning.
                        </p>

                        <p>
                          One type of reasoning draws upon proportional reasoning, but applies this incorrectly to the situation
                          with different sample sizes. For instance, a student may argue “3:5 is the same as 300:500, thus the
                          chances are equal.” Similarly, they might mention “chances are the same since denominator and numerator
                          are multiplied/divided by 100.”
                        </p>

                        <p>
                          Another type of reasoning stems from the student (correctly) understanding the context of the problem
                          as having two possible outcomes (win-lose; heads-tails). For these types of problems, students may
                          overgeneralize the chances for a single event as being equally likely and apply that idea to any
                          collection of results with any sample size. For example, a student might argue “because it is a fair
                          coin, then there is a 50% chance for heads and tails, thus both results have the same chance to occur.”
                        </p>

                        <p>
                          Students have also been seen to exhibit an illusion of equality because they believe that if a situation
                          is random, then all possible results from that situation have the same chance to occur.
                        </p>

                        <p>
                          Finally, some students hold a belief that all samples should represent a theoretical distribution, no
                          matter the sample size or how many samples are taken. They don’t appreciate variability between empirical
                          results and a theoretical distribution. In fact, they might say that 3 heads out of 5 fair coin tosses
                          is just as unlikely as 300 heads out of 500 tosses because “in both samples, there are not 50% heads and
                          tails.”
                        </p>

                        <p>
                          The activities below are intended to help students confront this misconception and overcome these biased ways of reasoning by examining results from empirical data.
                        </p>

                        <p>
                          Activity 1: <a href="./assets/userDownload/IOE_Activity_1_Hospital_Task.pdf" download target="_blank">Hospital Task</a>
                        </p>

                        <p>
                          Activity 2: <a href="./assets/userDownload/IOE_Activity_2_Homework_Spin.pdf" download target="_blank">Homework Spin</a>
                        </p>

                        <p>
                          Activity 3: <a href="./assets/userDownload/IOE_Activity_3_One_or_Two_Heads.pdf" download target="_blank">One or Two Heads?</a>
                        </p>

                        <hr></hr>

                        <h4>Additional Readings and Resources for Teaching Probability</h4>

                        <p>
                          <a href="./assets/userDownload/DICE_Free_Resources_for_Teaching_Probability_in_Middle_School.pdf" download target="_blank">This PDF document</a> contains
                          several readings, lesson plans, and online apps that can expand your instructional repertoire
                          for teaching probability concepts in middle school. The link to this document is available
                          under each of the Support: Classroom Activities tab for misconceptions on Form 1 (Exploring
                          Probability) and Form 2 (Chance and Data).
                        </p>
                      </div>
                    </div>
                  </div>

                  <br />

                  <div id="items" className="tab-pane" aria-labelledby='itemsTab'>
                    <h4>Misconception&nbsp;Responses</h4>
                    <div>
                      <p>
                        This page lists the questions that measure
                        the&nbsp;<em>Illusion of Equality</em>&nbsp;misconception.
                        Each question has
                        a ring chart showing the&nbsp;<span
                          style={{ color: "#2196f3ad" }}><strong>percentage of students in
                            the “needs support”
                            group</strong></span>&nbsp;who selected a misconception
                        response option, which is a response option indicative of
                        misconception&nbsp;reasoning. Hover over the purple part of the
                        ring to see the student counts.
                      </p>

                      <p>
                        The letter(s) inside the rings show the misconception response
                        option(s) for that question. Below each
                        ring is one or more examples of the kinds of student reasoning
                        that might lead a student to select the
                        misconception response option. The examples are drawn from the
                        DICE researchers’ interviews with students.
                      </p>

                      <p>
                        The questions are listed in order of the percentage, from high
                        to low. If you plan to review these
                        questions with students, the questions at the top of the list
                        may be most useful to review.
                      </p>
                    </div>

                    <div className="dotted_line pdb-50"></div>
                    {this.state.equiprobabilityPercent.map((item, index) => {
                      return (
                        <div
                          className="row mt-30 dotted_line pdb-50">
                          <div
                            className="col-md-6">
                            <div
                              className="row">
                              <span
                                className="learnosity-item"
                                data-reference="{ item }"><img alt='' src={this.showQuestionEquiprobability(item.ItemID)} style={{ height: "175%", width: "110%" }} /></span>
                              <div
                                className="col-md-12 ml-22 mt-1 font-18">
                                <b>
                                </b>
                              </div>

                              <div
                                className="col-md-12">
                                {/* <span
                                  className="learnosity-item"
                                  data-reference="{ item }"><img alt='' src={this.showQuestionEquiprobability(item.ItemID)} style={{ height: "150%", width: "100%" }} /></span> */}
                              </div>
                            </div>
                          </div>

                          <div
                            className="col-md-6" style={{ paddingLeft: "100px" }}>
                            {this.showExampleStudentReasoningEquiprobability(item, "Equiprobability")}
                          </div>
                        </div>

                      )
                    })
                    }

                  </div>
                </div>
              </div>

              <div id="ignore" className="tab-pane">
                <div className="row mt-30">
                  <ul className="nav nav-tabs new_nav_tab" role="tablist">
                    <li className="col-md-4 active"><a data-toggle="tab" role="tab" aria-expanded="true"
                      href="#student_groups_ignore" className="btn">Who Needs Support</a></li>

                    <li className="col-md-4"><a data-toggle="tab" role="tab" href="#items_ignore" aria-expanded="false"
                      className="btn">Support: Review the Misconception<br />Responses</a>
                    </li>

                    <li className="col-md-4"><a data-toggle="tab" role="tab" aria-expanded="false"
                      href="#activities_ignore" className="btn">Support: Classroom
                      Activities</a></li>
                  </ul>
                </div>
                <br />

                <div className="tab-content report_tabs">
                  <div id="student_groups_ignore" className="tab-pane active">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row circle-note">
                          <h4>Instructional Support Groups</h4>
                          Based on the assessment results, each student is grouped into
                          their most likely instructional group: Needs Support or Does Not
                          Need Support. A filled in circle indicates stronger evidence for
                          the group assignment.
                        </div>

                        <div className="row">
                          <div className="col-md-4 bound-student-list">
                            <div className="student_head">
                              <b>Needs Support on <i>Ignoring Relative Frequency</i></b>
                              <br />
                              ({this.state.need_support_ignore_open_blue.length + this.state.need_support_ignore_solid_blue.length} students)
                            </div>
                            {this.state.need_support_ignore_solid_blue.map((item) => {
                              return (
                                <div>
                                  <div className="box need"></div>
                                  {item}

                                  <p></p>
                                  <p>
                                  </p>
                                </div>
                              );
                            })
                            }
                            {
                              this.state.need_support_ignore_open_blue.map((item) => {
                                return (
                                  <div>
                                    <div className="blue_box_stripes"></div>

                                    {item}
                                    <p></p>
                                    <p>
                                    </p>
                                  </div>
                                );
                              })
                            }
                          </div>


                          <div className="col-md-4 bound-student-list">
                            <div className="student_head">
                              <b>Does Not Need Support on <i>Ignoring
                                Independence</i></b><br />
                              ({this.state.does_not_need_support_ignore_open_green.length + this.state.does_not_need_support_ignore_solid_green.length} students)
                            </div>
                            {
                              this.state.does_not_need_support_ignore_solid_green.map((item) => {
                                return (
                                  <div>
                                    <div className="box understand"></div>

                                    {item}
                                    <p></p>

                                    <p>
                                    </p>
                                  </div>
                                );

                              })
                            }
                            {
                              this.state.does_not_need_support_ignore_open_green.map((item) => {
                                return (
                                  <div>
                                    <div className="green_box_stripes"></div>

                                    {item}
                                    <p></p>

                                    <p>
                                    </p>
                                  </div>
                                );

                              })
                            }

                          </div>

                          <div className="col-md-3 bound-student-list incomplete">
                            <div className="student_head">
                              <b>Incomplete Assessment</b><br />
                              {/* ({other_users.length} students) */}
                            </div>
                            {/* {  other_users.each do |user| } */}
                            <p>
                              {/* {user.short_name} */}
                            </p>
                            {/* {end} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div id="activities_ignore" className="tab-pane mt-30">
                  <div class="row">
                      <div class="col-md-12">
                        <div class="row circle-note">
                          <h4>Activities for Targeted Learning Related to <em>Ignoring Relative Frequencies</em></h4>

                          <p>
                            Activities provided here are designed to assist students in confronting a bias towards ignoring
                            relative frequencies and making probability judgements by only comparing frequencies of outcomes
                            in a sample space. For example, students often think that if you have more (or less) of a certain
                            outcome in a sample space, regardless of the total number of all possible outcomes, that this
                            leads to a higher (or lower) chance of an event occurring. A student may also focus on the total
                            number of outcomes and believe that a smaller (or larger) total would increase the chance of a
                            particular outcome.
                          </p>

                          <p>
                            The suggested activities are meant to give students an opportunity to compare the probability of
                            specific outcomes by first finding the probability of each outcome in its sample space, rather
                            than merely comparing the frequencies and making decisions based only on those frequencies.
                          </p>

                          <p>
                            Activity 1: <a href="./assets/userDownload/IRF_Activity_1_Jelly_Beans.pdf" download target="_blank">Jelly Beans</a>
                          </p>

                          <p>
                            Activity 2: <a href="./assets/userDownload/IRF_Activity_2_Unknown_Number_of_Jelly_Beans.pdf" download target="_blank">Unknown Number of Jelly Beans</a>
                          </p>

                          <p>
                            Activity 3: <a href="./assets/userDownload/IRF_Activity_3_Comparing_Probability_Comparison_Tasks.pdf" download target="_blank">Comparing Probability Comparison Tasks</a>
                          </p>

                          <p>
                            Note: Activity 3 involves both the <em>Ignoring Relative Frequencies</em> misconception and the <em>Illusion
                            of Equality</em> misconception. Students compare two sample spaces (i.e., theoretical probabilities)
                            as a way to explore relative frequencies. Students compare <em>results</em> from two sample spaces (i.e.,
                            empirical probabilities) to explore the illusion of equality.
                          </p>

                          <hr></hr>

                          <h4>Additional Readings and Resources for Teaching Probability</h4>

                          <p>
                            <a href="./assets/userDownload/DICE_Free_Resources_for_Teaching_Probability_in_Middle_School.pdf" download target="_blank">This PDF document</a> contains
                            several readings, lesson plans, and online apps that can expand your instructional repertoire
                            for teaching probability concepts in middle school. The link to this document is available
                            under each of the Support: Classroom Activities tab for misconceptions on Form 1 (Exploring
                            Probability) and Form 2 (Chance and Data).
                          </p>
                        </div>
                      </div>
                    </div>
                </div>

                <br />

                <div id="items_ignore" className="tab-pane">
                  <h4>Misconception Responses</h4>
                  <div>
                    <p>
                      This page lists the questions that measure the <em>Ignoring
                        Independence</em> misconception. Each question has
                      a ring chart showing the <span
                        style={{ color: "#2196f3ad" }}><strong>percentage of students in the
                          “needs support”
                          group</strong></span> who selected a misconception response
                      option, which is a response option indicative of
                      misconception reasoning. Hover over the purple part of the ring to
                      see the student counts.
                    </p>

                    <p>
                      The letter(s) inside the rings show the misconception response
                      option(s) for that question. Below each
                      ring is one or more examples of the kinds of student reasoning that
                      might lead a student to select the
                      misconception response option. The examples are drawn from the DICE
                      researchers’ interviews with students.
                    </p>

                    <p>
                      The questions are listed in order of the percentage, from high to
                      low. If you plan to review these
                      questions with students, the questions at the top of the list may be
                      most useful to review.
                    </p>
                  </div>


                  <div className="dotted_line pdb-50"></div>
                  {this.state.ignoreprobabilityPercent.map((item, index) => {
                    return (
                      <div
                        className="row mt-30 dotted_line pdb-50">
                        <div
                          className="col-md-6">
                          <div
                            className="row"

                          >
                            <div
                              className="col-md-12 ml-22 mt-1 font-18">
                              <b>
                              </b>
                            </div>

                            <div
                              className="col-md-12">
                              <span
                                className="learnosity-item"
                                data-reference="{ item }"><img alt='' src={this.showQuestionEquiprobability(item.ItemID)} style={{ height: "175%", width: "110%" }} /></span>
                            </div>
                          </div>
                        </div>

                        <div
                          className="col-md-6" style={{ paddingLeft: "100px" }}>
                          {this.showExampleStudentReasoningEquiprobability(item, "Ignore")}
                        </div>
                      </div>
                    )

                  })
                  }
                </div>
              </div>
            </div>



            <div id="detailed_data" className="tab-pane">
              <div className="row mt-30">
                <ul className="nav nav-tabs font-16 new_nav_tab" role="tablist">
                  <li className="active col-md-4"><a data-toggle="tab" role="tab"
                    href="#res1_rep" className="btn">Responses Report</a></li>

                  <li className="col-md-4"><a data-toggle="tab" role="tab"
                    href="#misconception1" className="btn">Misconceptions Report</a>
                  </li>

                  {/* <li className="col-md-4"><a data-toggle="tab" role="tab" href="#scored1"
                                    className="btn">Scored Report</a></li> */}
                </ul>
              </div>

              <div className="tab-content detailed_report_tabs mt-30">
                <div id="res1_rep" className="tab-pane active">
                  <div id="table-scroll" className="table-scroll">
                    <table
                      className="table class_results table-striped table-bordered main-table"
                      id="main-table" style={{ width: "auto" }}>
                      <thead>
                        <tr>
                          <th colSpan={this.state.tableRows.length - 5}
                            className="text-center">
                            RESPONSES REPORT
                          </th>
                          <th colSpan="5" className="text-center">
                          </th>
                        </tr>
                        <tr>
                          <th colSpan="8" className="text-center">
                            Teacher
                          </th>
                          <th colSpan="11" className="text-center">KEY:
                            A/B/C/D/E=Selected Response; S=Skipped</th>
                        </tr>

                        <tr>
                          <th style={{ top: "0px" }} colSpan="4" className="text-center">Student Info
                          </th>
                          <th colSpan={this.state.tableRows.length - 3} style={{ top: "0px" }}
                            className="text-center">Questions</th>
                        </tr>
                        <tr className="resp_report">
                          <th style={{ top: "0px" }}>#</th>
                          <th style={{ top: "0px" }}><span
                            style={{ padding: "2px", whitespace: "nowrap" }}>Username</span>
                          </th>
                          <th style={{ top: "0px" }}><span
                            style={{ padding: "2px", whitespace: "nowrap" }}>First</span>
                          </th>
                          <th style={{ top: "0px" }}><span
                            style={{ padding: "6px", whitespace: "nowrap" }}>Last</span>
                          </th>
                          {this.state.tableRows.slice(3, 18).map((row, i) =>
                            <th className="set_extra" style={{ top: "0px" }}>
                              {row}
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {
                          this.state.values.map((row, i) => {
                            return (
                              < tr className="resp_report">
                                <td>{i + 1}</td>
                                <td>{row[0]}</td>
                                <td>{row[1]}</td>
                                <td>{row[2]}</td>
                                <td>{row[3]}</td>
                                <td>{row[4]}</td>
                                <td>{row[5]}</td>
                                <td>{row[6]}</td>
                                <td>{row[7]}</td>
                                <td>{row[8]}</td>
                                <td>{row[9]}</td>
                                <td>{row[10]}</td>
                                <td>{row[11]}</td>
                                <td>{row[12]}</td>
                                <td>{row[13]}</td>
                                <td>{row[14]}</td>
                                <td>{row[15]}</td>
                                <td>{row[16]}</td>
                                <td>{row[17]}</td>
                              </tr>
                            )

                          })}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div id="misconception1" className="tab-pane">
                  <div id="table-scroll" className="table-scroll">
                    <table className="table class_results table-striped table-bordered"
                      id="tblData" style={{ width: "auto" }}>
                      <thead>

                        <tr>
                          <th colSpan="20" className="text-center">
                            MISCONCEPTION REPORT
                          </th>

                          <th colSpan="6" className="text-center">
                          </th>
                        </tr>
                        <tr>
                          <th colSpan="7" className="text-center">
                            Teacher
                          </th>
                          <th colSpan="10" className="text-center">
                            Form 1.1
                          </th>
                          <th colSpan="11" className="text-center">
                            <div>KEY: C=Correct; P=Illusion of Equality; R=Ignoring Relative Frequency; O=Other; S=Skipped;
                              '-' =This question does not measure this misconception
                            </div>
                          </th>
                        </tr>


                        <tr className="perc_m1 grayed">
                          <td rowSpan="6" colSpan="3"
                            className="vert-head text-center">
                            <b>Question Level Summaries</b>
                          </td>
                          <td><b>Total C</b></td>
                          {
                            this.state.columnDetailedDataTab.length > 0 ?
                              <>
                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountC[0].Q1}</b></td>
                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountC[0].Q2}</b></td>
                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountC[0].Q3}</b></td>
                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountC[0].Q4}</b></td>
                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountC[0].Q5}</b></td>
                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountC[0].Q6}</b></td>
                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountC[0].Q7}</b></td>
                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountC[0].Q8}</b></td>
                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountC[0].Q9}</b></td>
                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountC[0].Q10}</b></td>
                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountC[0].Q11}</b></td>
                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountC[0].Q12}</b></td>
                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountC[0].Q13}</b></td>
                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountC[0].Q14}</b></td>
                              </>
                              :
                              <td></td>

                          }
                          <td rowSpan="6" colSpan="8"
                            className="grayed"></td>
                        </tr>
                        <tr className="perc_m1">
                          <td className="bb3p"><b>Total %C</b></td>
                          {
                            this.state.columnDetailedDataTab.length > 0 ?
                              <>
                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountC[0].Q1) * 100) / this.state.values.length)}</b></td>
                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountC[0].Q2) * 100) / this.state.values.length)}</b></td>
                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountC[0].Q3) * 100) / this.state.values.length)}</b></td>
                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountC[0].Q4) * 100) / this.state.values.length)}</b></td>
                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountC[0].Q5) * 100) / this.state.values.length)}</b></td>
                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountC[0].Q6) * 100) / this.state.values.length)}</b></td>
                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountC[0].Q7) * 100) / this.state.values.length)}</b></td>
                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountC[0].Q8) * 100) / this.state.values.length)}</b></td>
                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountC[0].Q9) * 100) / this.state.values.length)}</b></td>
                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountC[0].Q10) * 100) / this.state.values.length)}</b></td>
                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountC[0].Q11) * 100) / this.state.values.length)}</b></td>
                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountC[0].Q12) * 100) / this.state.values.length)}</b></td>
                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountC[0].Q13) * 100) / this.state.values.length)}</b></td>
                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountC[0].Q14) * 100) / this.state.values.length)}</b></td>
                              </>
                              :
                              <td></td>
                          }
                        </tr>
                        <tr className="perc_m1 grayed">
                          <td><b>Total P</b></td>
                          {
                            this.state.columnDetailedDataTab.length > 0 ?
                              <>
                                {/* <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountE[0].Q1}</b></td>
                          <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountE[0].Q2}</b></td> */}
                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountE[0].Q1}</b></td>
                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountE[0].Q2}</b></td>
                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountE[0].Q3}</b></td>
                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountE[0].Q4}</b></td>
                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountE[0].Q5}</b></td>
                                {/* <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountE[0].Q6}</b></td>
                          <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountE[0].Q7}</b></td> */}
                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountE[0].Q6}</b></td>
                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountE[0].Q7}</b></td>
                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountE[0].Q8}</b></td>
                                {/* <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountE[0].Q9}</b></td> */}
                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountE[0].Q9}</b></td>
                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountE[0].Q10}</b></td>
                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountE[0].Q11}</b></td>
                                {/* <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountE[0].Q12}</b></td> */}
                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountE[0].Q12}</b></td>
                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountE[0].Q13}</b></td>
                                {/* <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountE[0].Q14}</b></td> */}
                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountE[0].Q14}</b></td>
                              </>
                              :
                              <td></td>
                          }
                        </tr>
                        <tr className="perc_m1">
                          <td className="bb3p"><b>Total %P</b></td>
                          {
                            this.state.columnDetailedDataTab.length > 0 ?
                              <>
                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountE[0].Q1) * 100) / this.state.values.length)}</b></td>
                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountE[0].Q2) * 100) / this.state.values.length)}</b></td>
                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountE[0].Q3) * 100) / this.state.values.length)}</b></td>
                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountE[0].Q4) * 100) / this.state.values.length)}</b></td>
                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountE[0].Q5) * 100) / this.state.values.length)}</b></td>
                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountE[0].Q6) * 100) / this.state.values.length)}</b></td>
                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountE[0].Q7) * 100) / this.state.values.length)}</b></td>
                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountE[0].Q8) * 100) / this.state.values.length)}</b></td>
                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountE[0].Q9) * 100) / this.state.values.length)}</b></td>
                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountE[0].Q10) * 100) / this.state.values.length)}</b></td>
                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountE[0].Q11) * 100) / this.state.values.length)}</b></td>
                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountE[0].Q12) * 100) / this.state.values.length)}</b></td>
                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountE[0].Q13) * 100) / this.state.values.length)}</b></td>
                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountE[0].Q14) * 100) / this.state.values.length)}</b></td>
                                {/* <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountE[0].Q15) * 100) / this.state.values.length)}</b></td> */}
                              </>
                              :
                              <td></td>
                          }
                        </tr>
                        <tr className="perc_m1 grayed">
                          <td><b>Total R</b></td>
                          {
                            this.state.columnDetailedDataTab.length > 0 ?
                              <>
                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountI[0].Q1}</b></td>
                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountI[0].Q2}</b></td>
                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountI[0].Q3}</b></td>
                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountI[0].Q4}</b></td>
                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountI[0].Q5}</b></td>
                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountI[0].Q6}</b></td>
                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountI[0].Q7}</b></td>
                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountI[0].Q8}</b></td>
                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountI[0].Q9}</b></td>
                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountI[0].Q10}</b></td>
                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountI[0].Q11}</b></td>
                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountI[0].Q12}</b></td>
                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountI[0].Q13}</b></td>
                                <td className="bb3p"><b>{this.state.columnDetailedDataTab[0].colCountI[0].Q14}</b></td>
                                {/* <td className="bb3p"><b>-</b></td> */}
                              </>
                              :
                              <td></td>
                          }
                        </tr>

                        <tr className="perc_m1">
                          <td className="bb3p"><b>Total %R</b></td>
                          {
                            this.state.columnDetailedDataTab.length > 0 ?
                              <>
                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountI[0].Q1) * 100) / this.state.values.length)}</b></td>
                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountI[0].Q2) * 100) / this.state.values.length)}</b></td>
                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountI[0].Q3) * 100) / this.state.values.length)}</b></td>
                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountI[0].Q4) * 100) / this.state.values.length)}</b></td>
                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountI[0].Q5) * 100) / this.state.values.length)}</b></td>
                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountI[0].Q6) * 100) / this.state.values.length)}</b></td>
                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountI[0].Q7) * 100) / this.state.values.length)}</b></td>
                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountI[0].Q8) * 100) / this.state.values.length)}</b></td>
                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountI[0].Q9) * 100) / this.state.values.length)}</b></td>
                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountI[0].Q10) * 100) / this.state.values.length)}</b></td>
                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountI[0].Q11) * 100) / this.state.values.length)}</b></td>
                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountI[0].Q12) * 100) / this.state.values.length)}</b></td>
                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountI[0].Q13) * 100) / this.state.values.length)}</b></td>
                                <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountI[0].Q14) * 100) / this.state.values.length)}</b></td>
                                {/* <td className="bb3p"><b>{Math.ceil(((this.state.columnDetailedDataTab[0].colCountI[0].Q1) * 100) / this.state.values.length)}</b></td> */}
                              </>
                              :
                              <td></td>
                          }
                        </tr>

                        <tr>
                          <th colSpan="4" className="text-center">
                            Student Info</th>
                          <th colSpan={this.state.tableRows.length - 3}
                            className="text-center">Questions</th>
                          <th colSpan="9" className="text-center">
                            Student Summaries</th>
                        </tr>
                        <tr>
                          <th>#</th>
                          <th><span
                            style={{ padding: "2px", whitespace: "nowrap" }}>Username</span>
                          </th>
                          <th><span
                            style={{ padding: "2px", whitespace: "nowrap" }}>First</span>
                          </th>
                          <th><span
                            style={{ padding: "16px", whitespace: "nowrap" }}>Last</span>
                          </th>
                          {/* {items.length.times do |i| } */}
                          {this.state.tableRows.slice(3, 18).map((row, i) =>
                            <th className="set_extra" style={{ top: "0px" }}>
                              {row}
                            </th>
                          )}
                          {/* <th
                      className="set_extra { (i ==  items.length-1) ? 'bw2p' : '' }">
                      {/* {(i + 1).to_s} 
                    </th> */}
                          <th className="set_width"><span
                            style={{ padding: "2px", whitespace: "nowrap" }}
                            data-toggle='tooltip'
                            title='This is the number of questions that the student answered correctly.'>Total
                            C</span></th>
                          <th className="set_width bw2p"><span
                            style={{ padding: "2px", whitespace: "nowrap" }}
                            data-toggle='tooltip'
                            title='This is the percent of questions that the student answered correctly. Note: The test had 15 questions.'>C%
                            (Total C/14)</span></th>
                          <th className="set_width"><span
                            style={{ padding: "4px", whitespace: "nowrap" }}
                            data-toggle='tooltip'
                            title='This is the number of questions for which the student selected an Illusion of Equality response option.'>Total
                            P</span></th>
                          <th className="set_width bw2p"><span
                            style={{ padding: "4px", whitespace: "nowrap" }}
                            data-toggle='tooltip'
                            title='This is the percent of questions for which the student selected an Illusion of Equality response option. Note: The test had 8 questions that had an Illusion of Equality response option.'>%E
                            (Total P/7)</span></th>
                          <th className="set_width"><span
                            style={{ padding: "4px", whitespace: "nowrap" }}
                            data-toggle='tooltip'
                            title='This is the number of questions for which the student selected an Ignoring Relative Frequency response option.'>Total
                            R</span></th>
                          <th className="set_width bw2p"><span
                            style={{ padding: "4px", whitespace: "nowrap" }}
                            data-toggle='tooltip'
                            title='This is the percent of questions for which the student selected an Ignoring Relative Frequency response option. Note: The test had 9 questions that had an Ignoring Relative Frequency response option.'>I%
                            (Total R/7)</span></th>
                          <th><span
                            style={{ padding: "2px", whitespace: "nowrap" }}>First</span>
                          </th>
                          <th className="bw2p"><span
                            style={{ padding: "6px", whitespace: "nowrap" }}>Last</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          this.state.misconceptionMatrix.map((row, i) => {
                            return (
                              < tr className="resp_report">
                                <td>{i + 1}</td>
                                <td>{row[0].Username}</td>
                                <td>{row[1].Firstname}</td>
                                <td>{row[2].Lastname}</td>
                                <td>{row[0].Q1}</td>
                                <td>{row[1].Q2}</td>
                                <td>{row[2].Q3}</td>
                                <td>{row[3].Q4}</td>
                                <td>{row[4].Q5}</td>
                                <td>{row[5].Q6}</td>
                                <td>{row[6].Q7}</td>
                                <td>{row[7].Q8}</td>
                                <td>{row[8].Q9}</td>
                                <td>{row[9].Q10}</td>
                                <td>{row[10].Q11}</td>
                                <td>{row[11].Q12}</td>
                                <td>{row[12].Q13}</td>
                                <td>{row[13].Q14}</td>
                                {/* <td className="bw2p"><b>{row[14].Q15}</b></td> */}
                                <td><b>{row[14].C}</b></td>
                                <td className="bw2p"><b>{Math.ceil(((row[14].C) * 100) / 15)}</b></td>
                                <td><b>{row[14].E}</b></td>
                                <td className="bw2p"><b>{Math.ceil(((row[14].E) * 100) / 8)}</b></td>
                                <td><b>{row[14].I}</b></td>
                                <td className="bw2p"><b>{Math.ceil(((row[14].I) * 100) / 9)}</b></td>
                                <td><b>{row[1].Firstname}</b></td>
                                <td className="bw2p"><b>{row[2].Lastname}</b></td>
                              </tr>
                            )
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div id="scored1" className="tab-pane fade">
                  <div id="table-scroll1" className="table-scroll">
                    <table className="table class_results table-striped table-bordered"
                      id="tblData" style={{ width: "auto" }}>
                      <thead>
                        <tr>
                          <th colSpan="17" className="text-center">
                            SCORED REPORT
                          </th>

                          <th colSpan="5" className="text-center">
                          </th>
                        </tr>
                        <tr>
                          <th colSpan="5" className="text-center">
                          </th>
                          <th colSpan="7" className="text-center">
                          </th>
                          <th colSpan="10" className="text-center">
                            <div>KEY: 1=Correct Response; 0=Incorrect
                              Response </div><br />
                          </th>
                        </tr>

                        <tr>
                          <th colSpan="4" className="text-center">Student Info
                          </th>
                          <th colSpan="18" className="text-center">Questions
                          </th>
                        </tr>
                        <tr className="resp_report">
                          <th>#</th>
                          <th><span
                            style={{ padding: "2px", whitespace: "nowrap" }}>Username</span>
                          </th>
                          <th><span
                            style={{ padding: "2px", whitespace: "nowrap" }}>First</span>
                          </th>
                          <th><span
                            style={{ padding: "6px", whitespace: "nowrap" }}>Last</span>
                          </th>
                          <th className="set_extra">
                          </th>

                          <th className="perc_c">%C</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-center" colSpan="6">No record
                            found</td>
                        </tr>
                        <tr className="resp_report1">
                          <td colSpan="4" className="text-center">
                            <strong>%C</strong>
                          </td>
                        </tr>
                        <tr className="resp_report">
                          {/* {i = i + 1} */}
                          <td>
                            {/* {i} */}
                          </td>
                          <td>
                            {/* {all_users[key].try(:user_name)
                                                                                        } */}
                          </td>
                          <td>
                            {/* {all_users[key].try(:first_name)
                                                                                        } */}
                          </td>
                          <td>
                            {/* {all_users[key].try(:last_name)
                                                                                        } */}
                          </td>

                          {/* {15.times do |i| } */}
                          <td>
                            {/* {value[(i + 1)].present ?
                                                                                            ?
                  (value[(i + 1)])
                  : '0' } */}
                          </td>
                          {/* {end} */}
                          <td
                            className="perc_c">
                            <strong>
                              {/* {((100
                  *
                  value.values.count(1).to_f) /
                  15.0).round(1)
                } */}
                            </strong>
                          </td>
                        </tr>
                        {/* {end} */}

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

      </> : ""
  }
      </div>
    );
  }
}
const mapStatetoProps = state => {
  return {
    parsedData: state.parsedData,
    tableRows: state.tableRows,
    values: state.values
  };
};
export default connect(mapStatetoProps)(ChanceAndData);
// export default App;
